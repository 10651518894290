import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { setIsForbidden, setHasMultipleLocations, setUserRoles } from './features/Permissions/permissionsSlice'

function UserPermissions() {
  const dispatch = useDispatch()

  const locationCode = useSelector((state) => state.user.locationCode)

  const GET_USER_PERMISSIONS = gql`query
    userPermissions($siteLocationCode: String!) {
      userPermissions(siteLocationCode: $siteLocationCode) {
        isForbidden,
        hasMultipleLocations,
        userRoles
      }     
    }`

  const { data } = useQuery(GET_USER_PERMISSIONS, {
    variables: { siteLocationCode: locationCode },
    skip: !locationCode
  })

  dispatch(setIsForbidden(data?.userPermissions?.isForbidden))
  dispatch(setHasMultipleLocations(data?.userPermissions?.hasMultipleLocations))
  dispatch(setUserRoles(data?.userPermissions?.userRoles))

  useEffect(() => {
    window.sessionStorage.setItem('isForbidden', data?.userPermissions?.isForbidden)
    window.sessionStorage.setItem('hasMultipleLocations', data?.userPermissions?.hasMultipleLocations)
    window.sessionStorage.setItem('userRoles', data?.userPermissions?.userRoles)
  }, [data])
}

export default UserPermissions
