import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, gql } from '@apollo/client'
import { setIsColleagueSearchable, setIsManualEntry } from '../features/Searchee/searcheeSlice'

function useColleagueData() {
  const siteLocationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const searchId = useSelector((state) => state.search.id)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const GET_COLLEAGUES = gql`
    query ($locationCode: String!, $searchId: String!) {
      colleagueDataByStore(siteLocationCode: $locationCode, searchId: $searchId) {
        firstName,
        lastName,
        colleagueId,
        fullColleague {
          assignments {
            businessUnit,
            locationCode,
            jobName
          }
        }
      }
    }
  `
  const [getColleagueData, { data, loading, error }] = useLazyQuery(GET_COLLEAGUES, {
    variables: { locationCode: siteLocationCode, searchId }
  })

  useEffect(() => {
    if (siteLocationCode !== null && searchId !== null) {
      getColleagueData({ variables: { locationCode: siteLocationCode, searchId } })
        .then((response) => {
          if (response?.data?.colleagueDataByStore?.length === 0) {
            dispatch(setIsManualEntry(true))
            dispatch(setIsColleagueSearchable(false))
          }
        })
    }
  }, [siteLocationCode, searchId, getColleagueData, dispatch])

  function colleagueData() {
    return new Promise((resolve) => {
      const colleagueList = data?.colleagueDataByStore?.map((x) => ({
        label: `${x.firstName} ${x.lastName} (${x.colleagueId})`,
        // eslint-disable-next-line max-len
        value: `${x.firstName} ${x.lastName}|(${x.colleagueId})|${x.fullColleague?.assignments[0]?.jobName}|${x.fullColleague?.assignments[0]?.businessUnit}|${x.fullColleague?.assignments[0]?.locationCode}`
      }))
      resolve(colleagueList)
    })
  }

  const getColleagueOptions = () => colleagueData().then((response) => response)
    .catch((err) => {
      window.localStorage.removeItem('searchId')
      if (err.toString().includes('Forbidden')) {
        navigate('/forbidden')
      }
    })

  return {
    getColleagueOptions,
    loading,
    error
  }
}

export default useColleagueData
