import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ButtonText as Label1, Caption } from '@jsluna/typography'

function SearchTypeCountView({
  searchType, searchCount, viewItemsFoundCount, itemsFoundCount, id
}) {
  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left u-margin-top ln-u-margin-top*2" size={{ default: '11/12' }}>
        <Label1 id={`${id}-label`}>{searchType}</Label1>
      </GridItem>
      <GridItem className="ln-u-hard-left u-margin-top ln-u-margin-top*2" size={{ default: '1/12' }}>
        <Label1 id={`${id}-count`}>{searchCount}</Label1>
      </GridItem>
      {viewItemsFoundCount && (
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Caption id={`${id}-items-found-count`}>
            {
            itemsFoundCount === 1
              ? `${itemsFoundCount} item found`
              : `${itemsFoundCount} items found`
          }
          </Caption>
        </GridItem>
      )}
    </GridWrapper>
  )
}

SearchTypeCountView.propTypes = {
  searchType: PropTypes.string.isRequired,
  searchCount: PropTypes.number.isRequired,
  viewItemsFoundCount: PropTypes.bool.isRequired,
  itemsFoundCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired
}

export default SearchTypeCountView
