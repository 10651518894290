/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/button-has-type */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useLazyQuery, useQuery, gql } from '@apollo/client'
import { SiteLayout } from '@jsluna/site-layout'
import { Header as LunaHeader, HeaderLogo, HeaderNav } from '@jsluna/header'
import { Display2, Body1 } from '@jsluna/typography'
import { LinkButton } from '@jsluna/button'
import Logout from '../Logout/Logout'
import STORE_LOCATOR_BASE_URL from '../../constants/urls'
import removeAlphabeticChars from '../../utils/helper/stringHelper'
import {
  setSiteLocationCode,
  setSiteCode,
  setSiteBrand,
  setSiteType,
  setSelectedSiteCode,
  setSelectedSiteName,
  setSelectedSiteBrand,
  setSelectedSiteType
} from '../Site/siteSlice'
import { setSearcherName, setSearcherJobRole, setSearcherColleagueNumber } from '../Searcher/searcherSlice'
import LoadingSpinner from '../Progress/LoadingSpinner'
import {
  setSearchId,
  setSearchType,
  setSearchSubtype,
  setRefusedSearchReason,
  setSearchStatus,
  setCctvCaptured
} from '../Search/searchSlice'
import { setItems } from '../Items/itemsSlice'
import { setProgressFill } from '../Progress/progressSlice'
import {
  setSearcheeName,
  setIsManualEntry,
  setDrivesFor,
  setColleagueNumber,
  setSearcheeJobRole,
  setSearcheeBrand,
  setSearcheeType,
  setSearcheeVisitorCompany,
  setBusinessUnit,
  setLocationCode
} from '../Searchee/searcheeSlice'
import { setObserverName } from '../Observer/observerSlice'
import {
  SAINSBURYS, ARGOS, HABITAT, STORE, DEPOT, ARGOS_RETAIL, RETAIL, LOGISTICS, ARGOS_RFC, ARGOS_LFC, ARGOS_DISTRIBUTION
} from '../../utils/constants'
// import PassiveFeedback from '../PassiveFeedback/PassiveFeedback'

function Header() {
  const [storeName, setStoreName] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const primaryLocationCode = useSelector((state) => state.user.locationCode)
  const userAzureId = useSelector((state) => state.user.azureId)
  const userFullName = useSelector((state) => state.user.fullName)
  const userBusinessUnit = useSelector((state) => state.user.businessUnit)
  const userJobName = useSelector((state) => state.user.jobName)
  const id = useSelector((state) => state.search.id)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteName = useSelector((state) => state.site.selected.name)
  const hasMultipleLocations = useSelector((state) => state.permissions.hasMultipleLocations)
  const userRoles = useSelector((state) => state.permissions.userRoles) ?? []
  const displaySiteSelector = useSelector((state) => state.permissions.displaySiteSelector)

  const ADMIN = 'admin'

  useEffect(() => {
    if (window.localStorage.getItem('searchId') !== null && window.localStorage.getItem('progressFill') !== null) {
      dispatch(setProgressFill(parseFloat(window.localStorage.getItem('progressFill'))))
    }
  }, [dispatch])

  const GET_SEARCH = gql`
  query searchById ($siteLocationCode: String!, $id: String!) {
    searchById(siteLocationCode: $siteLocationCode, id: $id) {
      id,
      searchStatus,
      searchType,
      searchSubtype,
      cctvCaptured,
      refusedSearchReason,
      observer {
        name
      },
      searchee {
        name, colleagueNumber, addedManually, drivesFor, jobRole, brand, type, visitorCompany
      },
      items {
        id, name, price, location, reason
      }
    }
  }`

  const [getSearch] = useLazyQuery(GET_SEARCH, {
    variables: { id: window.localStorage.getItem('searchId') }
  })

  useEffect(() => {
    if ((id === null && window.localStorage.getItem('searchId') !== null && primaryLocationCode !== undefined) || window.localStorage.getItem('searchId') === 'null') {
      window.localStorage.setItem('pageRefreshed', 'true')
      getSearch({ variables: { siteLocationCode: primaryLocationCode, id: window.localStorage.getItem('searchId') } }).then((response) => {
        if (window.localStorage.getItem('progressFill') !== null) {
          dispatch(setProgressFill(parseFloat(window.localStorage.getItem('progressFill'))))
        }
        if (window.localStorage.getItem('searcheeAddedManually') !== null) {
          const isManual = window.localStorage.getItem('searcheeAddedManually') === 'true'
          dispatch(setIsManualEntry(isManual))
        }
        dispatch(setBusinessUnit(window.localStorage.getItem('searcheeBusinessUnit')))
        dispatch(setLocationCode(window.localStorage.getItem('searcheeLocationCode')))
        dispatch(setSearchId(response.data.searchById.id))
        dispatch(setSearchStatus(response.data.searchById.searchStatus))
        dispatch(setSearchType(response.data.searchById.searchType))
        dispatch(setRefusedSearchReason(response.data.searchById.refusedSearchReason))
        dispatch(setSearcheeName(response.data.searchById.searchee.name))
        dispatch(setDrivesFor(response.data.searchById.searchee.drivesFor))
        dispatch(setColleagueNumber(response.data.searchById.searchee.colleagueNumber))
        dispatch(setSearcheeJobRole(response.data.searchById.searchee.jobRole))
        dispatch(setSearcheeBrand(response.data.searchById.searchee.brand))
        dispatch(setSearcheeType(response.data.searchById.searchee.type))
        dispatch(setSearcheeVisitorCompany(response.data.searchById.searchee.visitorCompany))
        dispatch(setSearchSubtype(response.data.searchById.searchSubtype))
        dispatch(setCctvCaptured(response.data.searchById.cctvCaptured))
        dispatch(setObserverName(response.data.searchById.observer.name))
        const items = response.data.searchById.items.map((item) => {
          const searchItems = {
            id: '', name: '', price: 0, location: '', reason: ''
          }
          searchItems.id = item.id
          searchItems.name = item.name
          searchItems.price = item.price
          searchItems.location = item.location
          searchItems.reason = item.reason

          return searchItems
        })
        dispatch(setItems(items))
      }).catch(() => {
        window.localStorage.removeItem('searchId')
        navigate('/error')
      })
    }
  }, [getSearch, dispatch, id, primaryLocationCode, navigate])

  useEffect(() => {
    if (window.sessionStorage.getItem('selectedSiteCode') !== null) {
      dispatch(setSelectedSiteCode(window.sessionStorage.getItem('selectedSiteCode')))
    }

    if (window.sessionStorage.getItem('selectedSiteName') !== null) {
      dispatch(setSelectedSiteName(window.sessionStorage.getItem('selectedSiteName')))
      if (window.sessionStorage.getItem('selectedSiteName').includes(ARGOS)) {
        dispatch(setSelectedSiteBrand(ARGOS))
      } else if (window.sessionStorage.getItem('selectedSiteName').includes(HABITAT)) {
        dispatch(setSelectedSiteBrand(HABITAT))
      } else {
        dispatch(setSelectedSiteBrand(SAINSBURYS))
      }
      dispatch(setSelectedSiteType(STORE))
    }
  }, [dispatch])

  const getSiteCode = () => {
    if (selectedSiteCode != null) {
      window.sessionStorage.setItem('selectedSiteCode', selectedSiteCode)

      return selectedSiteCode
    }

    return removeAlphabeticChars(primaryLocationCode)
  }

  useEffect(() => {
    dispatch(setSiteLocationCode(primaryLocationCode))
    dispatch(setSiteCode(removeAlphabeticChars(primaryLocationCode)))
    dispatch(setSearcherName(userFullName))
    dispatch(setSearcherJobRole(userJobName))
  })

  const GET_COLLEAGUE_ID = gql`
    query ($locationCode: String!, $azureId: String!) {
      colleagueIdByAzureId(siteLocationCode: $locationCode, azureId: $azureId) {
        colleagueId
      }
    }
  `
  const { data, loading, error } = useQuery(GET_COLLEAGUE_ID, {
    variables: { locationCode: primaryLocationCode, azureId: userAzureId },
    skip: !primaryLocationCode || !userAzureId
  })

  useEffect(() => {
    dispatch(setSearcherColleagueNumber(data?.colleagueIdByAzureId?.colleagueId))
  }, [dispatch, data?.colleagueIdByAzureId?.colleagueId])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    console.log('Error getting searcher colleague id | ', error)
  }

  const getBrandOrStoreName = () => {
    const locationCodeLower = primaryLocationCode?.toLowerCase()
    const businessUnitLower = userBusinessUnit?.toLowerCase()

    if (selectedSiteName != null) {
      window.sessionStorage.setItem('selectedSiteName', selectedSiteName)

      if (locationCodeLower?.startsWith('al')) {
        dispatch(setSiteBrand(ARGOS))
        dispatch(setSiteType(STORE))
      }

      if (locationCodeLower?.startsWith('h')) {
        dispatch(setSiteBrand(HABITAT))
        dispatch(setSiteType(STORE))
      }

      if (locationCodeLower?.startsWith('l')) {
        if (businessUnitLower === RETAIL || businessUnitLower === ARGOS_RETAIL) {
          dispatch(setSiteBrand(SAINSBURYS))
          dispatch(setSiteType(STORE))
        }

        if (businessUnitLower === LOGISTICS) {
          dispatch(setSiteBrand(SAINSBURYS))
          dispatch(setSiteType(DEPOT))
        }

        if (businessUnitLower === ARGOS_RFC || businessUnitLower === ARGOS_LFC || businessUnitLower === ARGOS_DISTRIBUTION) {
          dispatch(setSiteBrand(ARGOS))
          dispatch(setSiteType(DEPOT))
        }
      }

      return selectedSiteName
    }

    if (locationCodeLower?.startsWith('al')) {
      dispatch(setSiteBrand(ARGOS))
      dispatch(setSiteType(STORE))

      return ARGOS
    }

    if (locationCodeLower?.startsWith('h')) {
      dispatch(setSiteBrand(HABITAT))
      dispatch(setSiteType(STORE))

      return HABITAT
    }

    if (locationCodeLower?.startsWith('l')) {
      if (businessUnitLower === RETAIL || businessUnitLower === ARGOS_RETAIL) {
        dispatch(setSiteBrand(SAINSBURYS))
        dispatch(setSiteType(STORE))
        fetch(`${STORE_LOCATOR_BASE_URL}/v1/stores/${primaryLocationCode.substring(1)}/?sort=code&api_client_id=Protect&fields=all`)
          .then((response) => response.json())
          .then((storeData) => {
            setStoreName(storeData.store.other_name)
          })
          .catch((err) => console.log('Unable to get store name or type | ', err))

        return storeName
      }

      if (businessUnitLower === LOGISTICS) {
        dispatch(setSiteBrand(SAINSBURYS))
        dispatch(setSiteType(DEPOT))

        return `Sainsbury's ${DEPOT}`
      }

      if (businessUnitLower === ARGOS_RFC || businessUnitLower === ARGOS_LFC || businessUnitLower === ARGOS_DISTRIBUTION) {
        dispatch(setSiteBrand(ARGOS))
        dispatch(setSiteType(DEPOT))

        return `${ARGOS} ${DEPOT}`
      }
    }

    return ''
  }

  const onChangeStore = () => {
    const siteCode = getSiteCode()
    const siteName = getBrandOrStoreName()
    const path = window.location.href.replace('//', '')
    const route = path.slice(path.indexOf('/'))
    navigate('/sites', { state: { incomingRoute: route, siteCode, siteName } })
  }

  return (
    <SiteLayout>
      <LunaHeader>
        <HeaderLogo>
          <Display2 id="header-title">Searches</Display2>
        </HeaderLogo>
        <HeaderNav
          className="c-header"
          drawer
          tabBar="max-md"
          label="Main"
          align="right"
        >
          <Body1
            className="u-colors-monochrome-dark"
            id="user-info"
            data-testid="user-data"
          >
            {`${userFullName} in ${getBrandOrStoreName()} (${getSiteCode()})`}
          </Body1>
          <>
            {((hasMultipleLocations && displaySiteSelector) || (userRoles?.includes(ADMIN) && displaySiteSelector))
            && (
            <LinkButton
              id="change-store-link"
              data-testid="change-store-link"
              className="c-items-found-remove-link ln-u-padding-ends ln-u-padding-sides*2"
              onClick={() => onChangeStore()}
              cta
              bare
            >
              Change store
            </LinkButton>
            )}
          </>
          {/* Feeback component has been temporarily commented out until it has been migrated over to azure ad */}
          {/* <PassiveFeedback user={user} /> */}
          <Logout />
        </HeaderNav>
      </LunaHeader>
    </SiteLayout>
  )
}

export default Header
