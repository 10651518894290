import { configureStore } from '@reduxjs/toolkit'
import featureToggleReducer from '../features/FeatureToggle/featureToggleSlice'
import trialSitesReducer from '../features/TrialSites/trialSitesSlice'
import progressReducer from '../features/Progress/progressSlice'
import userReducer from '../userSlice'
import permissionsReducer from '../features/Permissions/permissionsSlice'
import siteReducer from '../features/Site/siteSlice'
import randomiserReducer from '../features/Randomiser/randomiserSlice'
import searchReducer from '../features/Search/searchSlice'
import searcherReducer from '../features/Searcher/searcherSlice'
import searcheeReducer from '../features/Searchee/searcheeSlice'
import observerReducer from '../features/Observer/observerSlice'
import itemsReducer from '../features/Items/itemsSlice'
import searchHistoryReducer from '../features/SearchHistory/searchHistorySlice'
import errorReducer from '../features/Error/errorSlice'

const store = configureStore({
  reducer: {
    featureToggle: featureToggleReducer,
    trialSites: trialSitesReducer,
    progress: progressReducer,
    user: userReducer,
    permissions: permissionsReducer,
    site: siteReducer,
    randomiser: randomiserReducer,
    search: searchReducer,
    searcher: searcherReducer,
    searchee: searcheeReducer,
    observer: observerReducer,
    items: itemsReducer,
    searchHistory: searchHistoryReducer,
    error: errorReducer
  }
})

export default store
