import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Card } from '@jsluna/card'
import useRandomiserOutcomes from '../../customHooks/useRandomiserOutcomes'
import useSearches from '../../customHooks/useSearches'
import LoadingSpinner from '../Progress/LoadingSpinner'
import SearchesCompletedByDay from './SearchesCompletedByDay'
import SearchesCompletedInWeek from './SearchesCompletedInWeek'

function SearchesCompleted({ brand }) {
  const selectedPeriodWeekStartDate = useSelector((state) => state.searchHistory.selectedPeriodWeekStartDate)

  const {
    data: randomiserOutcomesData,
    loading: loadingRandomiserOutcomes,
    error: randomiserOutcomesError
  } = useRandomiserOutcomes(selectedPeriodWeekStartDate)

  const {
    data: searchesData,
    loading: loadingSearches,
    error: searchesError
  } = useSearches(selectedPeriodWeekStartDate)

  if (loadingRandomiserOutcomes || loadingSearches) {
    return <LoadingSpinner />
  }

  if (randomiserOutcomesError) {
    console.log('Error getting randomiser outcomes for completed searches by day | ', randomiserOutcomesError)
  }

  if (searchesError) {
    console.log('Error getting searches for completed searches by day | ', searchesError)
  }

  return (
    <>
      <Card className={brand && brand === 'all' ? 'ln-u-margin-top*2' : ''}>
        <SearchesCompletedInWeek
          randomiserOutcomesData={randomiserOutcomesData}
          searchesData={searchesData}
          brand={brand}
        />
      </Card>
      <Card className="ln-u-margin-top*2">
        <SearchesCompletedByDay
          randomiserOutcomesData={randomiserOutcomesData}
          searchesData={searchesData}
          brand={brand}
        />
      </Card>
    </>
  )
}

SearchesCompleted.propTypes = {
  brand: PropTypes.string.isRequired
}

export default SearchesCompleted
