/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  randomiser: {
    colleagueName: false
  },
  search: {
    subtype: false,
    cctvCaptured: false
  },
  searchee: {
    name: false,
    drivesOrWorksFor: false,
    companyName: false
  },
  observer: {
    name: false
  }
}

const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setColleagueNameError: (state, action) => {
      state.randomiser.colleagueName = action.payload
    },
    setSearcheeNameError: (state, action) => {
      state.searchee.name = action.payload
    },
    setDrivesOrWorksForError: (state, action) => {
      state.searchee.drivesOrWorksFor = action.payload
    },
    setSearchSubtypeError: (state, action) => {
      state.search.subtype = action.payload
    },
    setCompanyNameError: (state, action) => {
      state.searchee.companyName = action.payload
    },
    setCctvCapturedError: (state, action) => {
      state.search.cctvCaptured = action.payload
    },
    setObserverNameError: (state, action) => {
      state.observer.name = action.payload
    }
  }
})

export const {
  setColleagueNameError,
  setSearcheeNameError,
  setDrivesOrWorksForError,
  setSearchSubtypeError,
  setCompanyNameError,
  setCctvCapturedError,
  setObserverNameError
} = errorSlice.actions

export default errorSlice.reducer
