import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GridItem } from '@jsluna/grid'
import { RadioButtonField, TextInputField } from '@jsluna/form'
import { Text } from '@jsluna/typography'
import useColleagueData from '../../customHooks/useColleagueData'
import LoadingSpinner from '../Progress/LoadingSpinner'
import Searchee from './Searchee'
import { setSearchSubtype, setCctvCaptured, setSearchId } from '../Search/searchSlice'
import {
  setSearcheeName,
  setSearcheeBrand,
  setSearcheeVisitorCompany,
  setSearcheeType,
  setIsManualEntry,
  setIsColleagueSearchable
} from './searcheeSlice'
import { setObserverName } from '../Observer/observerSlice'
import {
  setObserverNameError,
  setCctvCapturedError,
  setCompanyNameError,
  setSearchSubtypeError,
  setDrivesOrWorksForError,
  setSearcheeNameError
} from '../Error/errorSlice'
import { reset } from '../Progress/progressSlice'
import useSearchee from '../../customHooks/useSearchee'
import useSearcheeContinue from '../../customHooks/useSearcheeContinue'
import useLocalStorage from '../../customHooks/useLocalStorage'
import useStateCustom from '../../customHooks/useStateCustom'
import {
  ARGOS, SAINSBURYS, VISITOR, ATM_CASH_OFFICE, LOCKER, PERSONAL, RANDOM, YES, NO, COLLEAGUE
} from '../../utils/constants'

function ColleagueDetails() {
  const {
    onNameChange,
    onAutoCompleteNameChange,
    onSelectName,
    getDefaultSelectedOption,
    onHyperlinkClick,
    searchValue
  } = useSearchee()
  const { loading, error, getColleagueOptions } = useColleagueData()
  const { onContinueClick } = useSearcheeContinue()
  const { removeLocationAndBusinessUnit } = useLocalStorage()
  const { resetSearcheeDetailsState, resetErrors, resetRandomiserAndNameState } = useStateCustom()

  const name = useSelector((state) => state.searchee.name)
  const isManual = useSelector((state) => state.searchee.isManualEntry)
  const isSearchable = useSelector((state) => state.searchee.isColleagueSearchable)
  const displaySearcheeNameError = useSelector((state) => state.error.searchee.name)
  const displayWorksForError = useSelector((state) => state.error.searchee.drivesOrWorksFor)
  const displaySearchSubtypeError = useSelector((state) => state.error.search.subtype)
  const displayCompanyNameError = useSelector((state) => state.error.searchee.companyName)
  const displayCctvError = useSelector((state) => state.error.search.cctvCaptured)
  const displayObserverNameError = useSelector((state) => state.error.observer.name)
  const searcheeBrand = useSelector((state) => state.searchee.brand)
  const searchSubtype = useSelector((state) => state.search.subtype)
  const cctvCaptured = useSelector((state) => state.search.cctvCaptured)
  const observerName = useSelector((state) => state.observer.name)
  const searcheeType = useSelector((state) => state.searchee.type)
  const visitorCompany = useSelector((state) => state.searchee.visitorCompany)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (searcheeType === '' && searcheeBrand !== '') {
      if (searcheeBrand === VISITOR) {
        dispatch(setSearcheeType(VISITOR))
      } else {
        dispatch(setSearcheeType(COLLEAGUE))
      }
    }
  }, [searcheeType, searcheeBrand, dispatch])

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    console.log('Error getting colleague names and ids | ', error)
  }

  const onBack = () => {
    window.localStorage.removeItem('searchId')
    window.localStorage.removeItem('progressFill')
    window.localStorage.removeItem('searcheeAddedManually')
    removeLocationAndBusinessUnit()
    window.localStorage.setItem('pageRefreshed', 'false')
    resetSearcheeDetailsState()
    resetRandomiserAndNameState()
    resetErrors()
    dispatch(setIsColleagueSearchable(true))
    dispatch(setSearchId(null))
    dispatch(setIsManualEntry(false))
    dispatch(reset())
    navigate('/randomiser')
  }

  const onSelectWorksFor = (e) => {
    const typeOfSearchee = e.target.value === VISITOR ? VISITOR : COLLEAGUE
    dispatch(setSearcheeType(typeOfSearchee))
    dispatch(setSearcheeBrand(e.target.value))
    dispatch(setDrivesOrWorksForError(false))
    if (e.target.value === VISITOR && isManual === false) {
      dispatch(setIsManualEntry(!isManual))
      dispatch(setSearcheeName(''))
      dispatch(setSearcheeNameError(false))
      dispatch(setSearchSubtypeError(false))
      dispatch(setCctvCapturedError(false))
      dispatch(setObserverNameError(false))
    }
  }

  const onSelectSearchSubtype = (e) => {
    dispatch(setSearchSubtype(e.target.value))
    dispatch(setSearchSubtypeError(false))
  }

  const onCompanyNameChange = (e) => {
    dispatch(setSearcheeVisitorCompany(e.target.value))
    if (e.target.value !== '') {
      dispatch(setCompanyNameError(false))
    }
  }

  const onSelectCoveredByCctv = (e) => {
    const isCctvCaptured = e.target.value === YES
    dispatch(setCctvCaptured(isCctvCaptured))
    dispatch(setCctvCapturedError(false))
    dispatch(setObserverName(''))
    dispatch(setObserverNameError(false))
  }

  const getCctvAnswer = () => {
    switch (cctvCaptured) {
      case null:
        return ''
      case true:
        return YES
      case false:
        return NO
      default:
        return ''
    }
  }

  const onObserverNameChange = (e) => {
    dispatch(setObserverName(e.target.value))
    if (e.target.value !== '') {
      dispatch(setObserverNameError(false))
    }
  }

  const additionalQuestion = () => (
    <>
      {(isManual && !isSearchable) === true
        ? (
          <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
            <RadioButtonField
              id="work-for-radio-btn"
              name="work-for-radio-btn"
              data-testid="work-for-radio-btn"
              className="ln-u-margin-bottom*2"
              value={searcheeBrand !== null ? searcheeBrand : ''}
              label="Who do they work for?"
              options={[
                { label: ARGOS, value: ARGOS },
                { label: VISITOR, value: VISITOR }
              ]}
              error={displayWorksForError ? 'Please select who they work for' : ''}
              onChange={(e) => onSelectWorksFor(e)}
            />
          </GridItem>
        ) : (
          <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
            <RadioButtonField
              id="work-for-radio-btn"
              name="work-for-radio-btn"
              data-testid="work-for-radio-btn"
              className="ln-u-margin-bottom*2"
              value={searcheeBrand !== null ? searcheeBrand : ''}
              label="Who do they work for?"
              options={[
                { label: ARGOS, value: ARGOS },
                { label: 'Sainsbury\'s', value: SAINSBURYS },
                { label: VISITOR, value: VISITOR }
              ]}
              error={displayWorksForError ? 'Please select who they work for' : ''}
              onChange={(e) => onSelectWorksFor(e)}
            />
          </GridItem>
        )}
      {(isManual && searcheeType === VISITOR) && (
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <TextInputField
          id="visitor-company-name-field"
          name="visitor-company-name-field"
          data-testid="visitor-company-name-field"
          value={visitorCompany}
          label="Company name"
          error={displayCompanyNameError ? 'Please enter the company name the visitor works for' : ''}
          onChange={(e) => onCompanyNameChange(e)}
        />
      </GridItem>
      )}
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <RadioButtonField
          id="search-subtype-radio-btn"
          name="search-subtype-radio-btn"
          data-testid="search-subtype-radio-btn"
          className="ln-u-margin-bottom*2"
          value={searchSubtype !== null ? searchSubtype : ''}
          label="Type of search"
          options={[
            { label: ATM_CASH_OFFICE, value: ATM_CASH_OFFICE },
            { label: LOCKER, value: LOCKER },
            { label: PERSONAL, value: PERSONAL },
            { label: RANDOM, value: RANDOM }
          ]}
          error={displaySearchSubtypeError ? 'Please select type of search' : ''}
          onChange={(e) => onSelectSearchSubtype(e)}
        />
      </GridItem>
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <RadioButtonField
          id="cctv-radio-btn"
          name="cctv-radio-btn"
          data-testid="cctv-radio-btn"
          className={cctvCaptured === false ? 'ln-u-margin-bottom*2' : 'ln-u-margin-bottom*4'}
          value={getCctvAnswer()}
          label="Is search being covered by CCTV?"
          options={[
            { label: YES, value: YES },
            { label: NO, value: NO }
          ]}
          error={displayCctvError ? 'Please select whether the search is being covered by CCTV' : ''}
          onChange={(e) => onSelectCoveredByCctv(e)}
        />
      </GridItem>
      {getCctvAnswer() === NO && (
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2" size={{ default: '1/1' }}>
        <Text
          id="observer-text"
          name="observer-text"
          data-testid="observer-text"
        >
          A specific search of a person needs another person of the same gender to observe, to make sure the search is being done fairly.
        </Text>
        <TextInputField
          id="observer-name-field"
          name="observer-name-field"
          data-testid="observer-name-field"
          className="ln-u-margin-top"
          value={observerName}
          label="Observer name"
          error={displayObserverNameError ? 'Please enter the observer\'s name' : ''}
          onChange={(e) => onObserverNameChange(e)}
        />
      </GridItem>
      )}
    </>
  )

  return (
    <Searchee
      isError={displaySearcheeNameError}
      fieldLabel="Colleague name"
      textInputLabel={searcheeType === '' ? 'Add colleague name manually' : `Add ${searcheeType.toLowerCase()} name manually`}
      errorMsg={searcheeType === '' ? 'Please enter colleague\'s name' : `Please enter the ${searcheeType.toLowerCase()}'s name`}
      onChange={onNameChange}
      onSelect={onSelectName}
      onAutoCompleteChange={onAutoCompleteNameChange}
      loadOptions={getColleagueOptions}
      onHyperlinkClick={onHyperlinkClick}
      hyperlinkText={isManual ? 'Search for colleague name' : 'Can\'t find the colleague?'}
      isColleagueSearchable={isSearchable}
      isManualEntry={isManual}
      questions={additionalQuestion()}
      textInputValue={name}
      autoCompleteFieldValue={searchValue}
      onSecondaryClick={onBack}
      onPrimaryClick={onContinueClick}
      defaultSelectedOption={getDefaultSelectedOption()}
    />
  )
}

export default ColleagueDetails
