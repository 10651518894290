/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Link } from '@jsluna/link'
import {
  Display3, Display1, Body1
} from '@jsluna/typography'
import useSearch from '../../customHooks/useSearch'
import useStateCustom from '../../customHooks/useStateCustom'
import useSite from '../../customHooks/useSite'
import { reset, decrement, setAllowBrowserBack } from '../Progress/progressSlice'
import { setDisplaySiteSelector } from '../Permissions/permissionsSlice'
import ColleagueSearch from './ColleagueSearch'
import VanSearch from './VanSearch'
import LoadingSpinner from '../Progress/LoadingSpinner'
import { setSiteHasArgosSIS, setSelectedSiteHasArgosSIS } from '../Site/siteSlice'
import { SAINSBURYS } from '../../utils/constants'

function Dashboard() {
  const { onSearchClick } = useSearch()
  const { resetSearcheeDetailsState, resetSearchState, resetRandomiserAndNameState } = useStateCustom()
  const primaryAssignedSiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primaryAssignedSiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const { data: primarySiteData, loading, error } = useSite(primaryAssignedSiteCode)
  const { data: selectedSiteData, loadingSelectedSiteData, selectedSiteError } = useSite(selectedSiteCode)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const progress = useSelector((state) => state.progress.fill)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  dispatch(setDisplaySiteSelector(true))
  dispatch(setAllowBrowserBack(false))

  if (primaryAssignedSiteBrand === SAINSBURYS) {
    dispatch(setSiteHasArgosSIS(primarySiteData?.site?.hasArgosSIS))
  } else {
    dispatch(setSiteHasArgosSIS(false))
  }

  if (selectedSiteBrand === SAINSBURYS) {
    if (selectedSiteData?.site?.hasArgosSIS !== undefined) {
      dispatch(setSelectedSiteHasArgosSIS(selectedSiteData?.site?.hasArgosSIS))
    }
  } else if (selectedSiteCode !== null) {
    dispatch(setSelectedSiteHasArgosSIS(false))
  }

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.removeItem('progressFill', progress)
      dispatch(reset())
    }
  }, [progress, dispatch])

  window.onpopstate = () => {
    resetSearcheeDetailsState()
    resetSearchState()
    resetRandomiserAndNameState()
    if (progress <= 1 / 2) {
      dispatch(reset())
    } else {
      dispatch(decrement())
    }
  }

  if (loading || loadingSelectedSiteData) {
    return <LoadingSpinner />
  }

  if (error || selectedSiteError) {
    console.log('Error getting site data | ', error)
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <Display3 id="dashboard-title" data-testid="dashboard-title" className="ln-u-margin-left*2">
          Searches to do
        </Display3>
      </GridItem>
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <Body1 id="dashboard-sub-text" data-testid="dashboard-sub-text" className="ln-u-margin-left*2">
          before 23:59 on Saturday
        </Body1>
      </GridItem>
      <ColleagueSearch />
      <VanSearch onClick={onSearchClick} />
      <Card className="ln-u-margin-top*2">
        <GridItem className="ln-u-hard-left ln-u-margin-bottom" size={{ default: '1/1' }}>
          <Display1 id="further-info-title">Further Information</Display1>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Link
            id="colleague-visitor-search-procedure-link"
            href="https://jsainsbury.sharepoint.com/:w:/r/sites/DocCentre/Retail-Procedures/_layouts/15/Doc.aspx?sourcedoc=%7b77D3748E-9F66-4D6E-B902-1440D2EF5976%7d&file=Security-%20Colleague%20and%20Visitor%20Search%20Procedure.docx&action=default&mobileredirect=true&DefaultItemOpen=1&isSPOFile=1&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjAzMDcwMTYxMCJ9&cid=432ec21c-b169-41ea-ab50-4470dcaa9c86"
          >
            Colleague and visitor search procedure
          </Link>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default Dashboard
