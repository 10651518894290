import React, { useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3, Display1, Body1 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { Link } from '@jsluna/link'
import { Button, FilledButton, OutlinedButton } from '@jsluna/button'
import LoadingSpinner from '../Progress/LoadingSpinner'
import {
  reset, decrement, increment, setProgressFill
} from '../Progress/progressSlice'
import {
  setSearchStatus,
  setSearchId,
  setSearchType,
  setSearchSubtype,
  setCctvCaptured,
  setRefusedSearchReason
} from '../Search/searchSlice'
import {
  setIsManualEntry,
  setSearcheeName,
  setDrivesFor,
  setColleagueNumber,
  setSearcheeJobRole,
  setBusinessUnit,
  setLocationCode,
  setSearcheeBrand,
  setSearcheeType,
  setSearcheeVisitorCompany,
  setIsColleagueSearchable
} from '../Searchee/searcheeSlice'
import { setObserverName } from '../Observer/observerSlice'
import { setRecentlyRemovedItem } from '../Items/itemsSlice'
import useLocalStorage from '../../customHooks/useLocalStorage'
import {
  GOL, FAST_TRACK, VAN, COLLEAGUE, SAINSBURYS, ARGOS, DRAFT, DONE_AND_NOTHING_FOUND, PERSONAL, RANDOM, LOCKER, ATM_CASH_OFFICE
} from '../../utils/constants'

function SearchSelection() {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { removeLocationAndBusinessUnit } = useLocalStorage()

  const id = useSelector((state) => state.search.id)
  const searchStatus = useSelector((state) => state.search.status)
  const allowBrowserBack = useSelector((state) => state.progress.allowBrowserBack)
  const progress = useSelector((state) => state.progress.fill)
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const FIELD_NAMES = ['SearchStatus']

  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const siteNumber = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType

  const drivesFor = useSelector((state) => state.searchee.drivesFor)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const searchType = useSelector((state) => state.search.type)
  const searchSubtype = useSelector((state) => state.search.subtype)
  const searcheeBrand = useSelector((state) => state.searchee.brand)

  const searchTypeDisplayName = searchType === COLLEAGUE ? 'person' : 'van'

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (searchStatus !== DRAFT || !allowBrowserBack) {
      navigate('/')
    }
  }, [searchStatus, allowBrowserBack, navigate])

  useEffect(() => {
    if (window.localStorage.getItem('searchId') !== null && window.localStorage.getItem('progressFill') !== null && progress !== null) {
      window.localStorage.setItem('progressFill', progress)
      dispatch(setProgressFill(parseFloat(window.localStorage.getItem('progressFill'))))
    }
  }, [dispatch, progress])

  const SEARCH = gql`mutation updateSearch (
    $siteLocationCode: String!,
    $searchId: String!,
    $fieldNames: [String!]!,
    $searchStatus: String!,
    ) {
    updateSearch(
      siteLocationCode: $siteLocationCode,
      id: $searchId,
      fieldNames: $fieldNames,
      search: {
      id: "",
      searchStatus: $searchStatus,
      site: {
        primaryAssigned: {},
        selected: {}
      },
      searcher: {},
      searchee: {},
      observer: {},
      items: []
    }) {
      id
    }
  }`

  const [searchCompleteUpdate] = useMutation(SEARCH)

  const GET_VAN_COUNTS = gql`query vanCounts($siteCode: String!, $siteBrand: String!, $siteType: String!) {
    vanCounts(siteCode: $siteCode, siteBrand: $siteBrand, siteType: $siteType) {
      gol,
      fastTrack,
      from,
      to
    }
  }`

  const GET_COLLEAGUE_COUNTS = gql`query colleagueCounts($siteCode: String!, $siteType: String!, $siteBrand: String!) {
    colleagueCounts(siteCode: $siteCode, siteType: $siteType, siteBrand: $siteBrand) {
      sainsburys,
      argos,
      from,
      to
    }
  }`

  const [getVanCounts, { loading }] = useLazyQuery(GET_VAN_COUNTS, {
    variables: { }
  })

  const [getColleagueCounts] = useLazyQuery(GET_COLLEAGUE_COUNTS, {
    variables: { }
  })

  const data = useRef()

  const colleagueData = useRef()

  useEffect(() => {
    if (siteNumber && siteType && siteBrand) {
      getVanCounts({ variables: { siteCode: siteNumber, siteBrand, siteType } }).then((response) => {
        data.current = response.data
      })
    }
  }, [getVanCounts, siteNumber, siteType, siteBrand])

  useEffect(() => {
    if (siteNumber && siteType) {
      getColleagueCounts({ variables: { siteCode: siteNumber, siteType, siteBrand } }).then((response) => {
        colleagueData.current = response.data
      })
    }
  }, [getColleagueCounts, siteNumber, siteType, siteBrand])

  if (loading) {
    return <LoadingSpinner />
  }

  const getRemainingVanCount = () => {
    if (drivesFor === GOL) {
      if (data?.current?.vanCounts?.gol > 1) {
        const golCount = data?.current?.vanCounts?.gol
        return golCount - 1
      }
      return 'No'
    }

    if (drivesFor === FAST_TRACK) {
      if (data?.current?.vanCounts?.fastTrack > 1) {
        const fastTrackCount = data?.current?.vanCounts?.fastTrack
        return fastTrackCount - 1
      }
      return 'No'
    }
    return ''
  }

  const getRemainingColleagueCount = () => {
    if (searcheeBrand === SAINSBURYS) {
      if (colleagueData?.current?.colleagueCounts?.sainsburys > 1) {
        const sainsburysCount = colleagueData?.current?.colleagueCounts?.sainsburys
        return sainsburysCount - 1
      }
      return 'No'
    }

    if (searcheeBrand === ARGOS) {
      if (colleagueData?.current?.colleagueCounts?.argos > 1) {
        const argosCount = colleagueData?.current?.colleagueCounts?.argos
        return argosCount - 1
      }
      return 'No'
    }
    return ''
  }

  const onNothingFoundClick = async () => {
    dispatch(reset())
    dispatch(setSearchStatus(DONE_AND_NOTHING_FOUND))
    dispatch(setRefusedSearchReason(null))
    window.localStorage.removeItem('progressFill')
    removeLocationAndBusinessUnit()
    window.localStorage.setItem('pageRefreshed', 'false')
    searchCompleteUpdate({
      variables: {
        siteLocationCode: locationCode, searchId: id, fieldNames: FIELD_NAMES, searchStatus: DONE_AND_NOTHING_FOUND
      }
    }).catch((error) => {
      if (error.networkError.toString().includes('403')) {
        navigate('/forbidden')
      } else {
        console.log(`Error updating search document with id ${id} | ${error}`)
        dispatch(reset())
        navigate('/error')
      }
    })

    if (searchType === VAN) {
      navigate('/outcome', { state: { title: 'Search completed', getRemainingCount: `${getRemainingVanCount()}`, outcomeType: drivesFor } })
    }

    if (searchType === COLLEAGUE) {
      navigate('/outcome', {
        state: {
          title: 'Search completed',
          getRemainingCount: `${getRemainingColleagueCount()}`,
          outcomeType: searcheeBrand === 'Sainsburys' ? 'Sainsbury\'s' : searcheeBrand
        }
      })
    }
  }

  const onItemsFoundClick = () => {
    dispatch(increment())
    dispatch(setRefusedSearchReason(null))
    dispatch(setRecentlyRemovedItem(false))
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/items')
  }

  const onRefusedSearchClick = () => {
    dispatch(increment())
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/refused/procedures')
  }

  window.onpopstate = () => {
    if (searchStatus === DRAFT && progress >= 2 / 3) {
      dispatch(decrement())
    } else {
      dispatch(increment())
    }

    if (searchStatus === DONE_AND_NOTHING_FOUND) {
      dispatch(setIsColleagueSearchable(true))
      dispatch(setIsManualEntry(false))
      dispatch(setSearcheeName(''))
      dispatch(setColleagueNumber(''))
      dispatch(setDrivesFor(''))
      dispatch(setSearcheeJobRole(''))
      dispatch(setBusinessUnit(''))
      dispatch(setLocationCode(''))
      dispatch(setSearcheeBrand(''))
      dispatch(setSearcheeType(''))
      dispatch(setSearcheeVisitorCompany(''))
      dispatch(setSearchSubtype(''))
      dispatch(setCctvCaptured(null))
      dispatch(setObserverName(''))
      dispatch(setSearchType(null))
      dispatch(setSearchId(null))
      dispatch(setSearchStatus(DRAFT))
      dispatch(reset())
      navigate('/')
    }
  }

  const onBack = () => {
    dispatch(decrement())
    window.localStorage.setItem('pageRefreshed', 'false')

    if (searchType === VAN) {
      navigate('/driver')
    }

    if (searchType === COLLEAGUE) {
      navigate('/colleague/details')
    }
  }

  const getSearchSubtypeDisplayName = () => {
    switch (searchSubtype) {
      case PERSONAL:
        return 'personal'
      case RANDOM:
        return 'random'
      case LOCKER:
        return 'locker'
      case ATM_CASH_OFFICE:
        return 'ATM room or Cash Office'
      default:
        return ''
    }
  }

  const onHyperlinkClick = (e) => {
    e.preventDefault()
    navigate('/colleague/procedures')
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2 u-margin-top" size={{ default: '1/1' }}>
        <Display3 id="title" className="ln-u-margin-left*2">{`Search the ${searchTypeDisplayName}`}</Display3>
      </GridItem>
      <Card>
        <GridItem
          className={searchType === COLLEAGUE ? 'ln-u-hard-left ln-u-margin-bottom' : 'ln-u-hard-left ln-u-margin-bottom*3'}
          size={{ default: '1/1' }}
        >
          <Display1 id="subtitle">Has anything been found?</Display1>
        </GridItem>
        {searchType === COLLEAGUE
        && (
        <GridItem className="ln-u-hard-left ln-u-margin-bottom*3" size={{ default: '1/1' }}>
          <Body1 id="subtext" data-testid="subtext">
            {'View a reminder of how to complete '}
            <Link
              id="further-guidance-link"
              data-testid="further-guidance-link"
              onClick={(e) => onHyperlinkClick(e)}
              href="/colleague/procedures"
            >
              <b>{`${getSearchSubtypeDisplayName()} searches`}</b>
            </Link>
            .
          </Body1>
        </GridItem>
        )}
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <FilledButton id="nothing-found-btn" fullWidth onClick={onNothingFoundClick}>Search done, nothing found</FilledButton>
        </GridItem>
        <GridItem className="ln-u-hard-left ln-u-margin-top*3" size={{ default: '1/1' }}>
          <FilledButton id="items-found-btn" fullWidth onClick={onItemsFoundClick}>Record items found</FilledButton>
        </GridItem>
        <GridItem className="ln-u-hard-left ln-u-margin-top*3" size={{ default: '1/1' }}>
          <OutlinedButton id="refused-search-btn" fullWidth onClick={onRefusedSearchClick}>Refused search</OutlinedButton>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Button id="back-btn" className="ln-u-margin-top*4" fullWidth onClick={onBack}>Back</Button>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default SearchSelection
