import { useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { COLLEAGUE } from '../utils/constants'

function useSearches(date) {
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const siteCode = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand

  const GET_COMPLETED_SEARCHES = gql`query 
    allCompletedSearches(
        $siteLocationCode: String!,
        $siteCode: String!,
        $siteBrand: String!,
        $siteType: String!,
        $searchType: String!,
        $date: DateTime!
    ) {
        allCompletedSearches(
        siteLocationCode: $siteLocationCode,
        siteCode: $siteCode,
        siteBrand: $siteBrand,
        siteType: $siteType,
        searchType: $searchType,
        date: $date) {
            id,
            searchStatus,
            searchSubtype,
            isRandomSearch,
            searchEnd,
            searcher {
                name,
                colleagueNumber
            },
            searchee {
              brand
            },
            items {
                id,
                name
            }
        }
    }`

  const { data, loading, error } = useQuery(GET_COMPLETED_SEARCHES, {
    variables: {
      siteLocationCode: locationCode, siteCode, siteBrand, siteType, searchType: COLLEAGUE, date
    },
    skip: !locationCode || !siteCode || !siteBrand || !siteType || !date
  })

  return {
    data,
    loading,
    error
  }
}

export default useSearches
