import React, { useEffect } from 'react'
import { Routes, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { loadDevMessages } from '@apollo/client/dev'
import { Modal } from '@jsluna/modal'
import { InteractionType } from '@azure/msal-browser'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsalAuthentication
} from '@azure/msal-react'
import { GridWrapper, GridItem } from '@jsluna/grid'
import User from './User'
import UserPermissions from './UserPermissions'
import FeatureToggle from './features/FeatureToggle/FeatureToggle'
import TrialSites from './features/TrialSites/TrialSites'
import LoadingSpinner from './features/Progress/LoadingSpinner'
import Header from './features/Header/Header'
import ProgressBar from './features/Progress/ProgressBar'
import Dashboard from './features/Dashboard/Dashboard'
import DriverDetails from './features/Searchee/DriverDetails'
import ColleagueDetails from './features/Searchee/ColleagueDetails'
import SearchHistory from './features/SearchHistory/SearchHistory'
import SearchSelection from './features/SearchSelection/SearchSelection'
import ItemsFound from './features/Items/ItemsFound'
import AddProduct from './features/Items/AddProduct'
import ProhibitedItems from './features/Items/ProhitibitedItems'
import RefusedSearchProcedures from './features/RefusedSearch/RefusedSearchProcedures'
import RefusedSearchReason from './features/RefusedSearch/RefusedSearchReason'
import SearchOutcome from './features/SearchOutcome/SearchOutcome'
import SiteSelector from './features/SiteSelector/SiteSelector'
import ColleagueSearchProcedures from './features/SearchProcedures/ColleagueSearchProcedures'
import Error from './features/Error/Error'
import Forbidden from './features/Error/Forbidden'
import Randomiser from './features/Randomiser/Randomiser'
import { apiConfig } from './utils/azure-ad/apiClientConfig'

function App() {
  const progress = useSelector((state) => state.progress.fill)
  const loading = useSelector((state) => state.progress.loading)

  useMsalAuthentication(InteractionType.Redirect, {
    scopes: [apiConfig.apiClientScope]
  })

  useEffect(() => {
    window.onunhandledrejection = (error) => {
      window.location.href = '/error'
      console.log('Application Error | Unhandled Promise Rejection | ', error)
    }
    window.onerror = (message, source, lineno, colno, error) => {
      window.location.href = '/error'
      console.log(`Application Error | Uncaught Exception | ${message} | ${source} | ${lineno} | ${colno} | ${error}`)
    }
  }, [])

  if (process.env.NODE_ENV !== 'production') {
    loadDevMessages()
  }

  return (
    <>
      <AuthenticatedTemplate>
        <User />
        {loading && <LoadingSpinner />}
        <UserPermissions />
        <FeatureToggle />
        <TrialSites />
        <Header />
        {progress && <ProgressBar />}
        <GridWrapper className="b-base-body ln-u-flush-left" horizontalAlign="center">
          <GridItem className="b-base-max-width ln-u-hard-left" size={{ default: '1/1' }}>
            <Routes>
              <Route exact path="/" element={<Dashboard />} />
              <Route path="/driver" element={<DriverDetails />} />
              <Route path="/colleague/details" element={<ColleagueDetails />} />
              <Route path="/history" element={<SearchHistory />} />
              <Route path="/selection" element={<SearchSelection />} />
              <Route path="/items" element={<ItemsFound />} />
              <Route path="/items/add" element={<AddProduct />} />
              <Route path="/items/prohibited" element={<ProhibitedItems />} />
              <Route path="/refused/procedures" element={<RefusedSearchProcedures />} />
              <Route path="/refused/reason" element={<RefusedSearchReason />} />
              <Route path="/outcome" element={<SearchOutcome />} />
              <Route path="/sites" element={<SiteSelector />} />
              <Route path="/colleague/procedures" element={<ColleagueSearchProcedures />} />
              <Route path="/error" element={<Error />} />
              <Route path="/forbidden" element={<Forbidden />} />
              <Route path="/randomiser" element={<Randomiser />} />
            </Routes>
          </GridItem>
        </GridWrapper>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Modal restrictClose open>
          <h4> Redirecting.. </h4>
        </Modal>
      </UnauthenticatedTemplate>
    </>
  )
}

export default App
