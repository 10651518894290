import { useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'

function useRandomiserOutcomes(date) {
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const siteCode = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand

  const GET_RANDOMISER_OUTCOMES = gql`query 
    allRandomiserOutcomes(
        $siteLocationCode: String!,
        $siteCode: String!,
        $siteBrand: String!,
        $siteType: String!,
        $date: DateTime!
    ) {
        allRandomiserOutcomes(
        siteLocationCode: $siteLocationCode,
        siteCode: $siteCode,
        siteBrand: $siteBrand,
        siteType: $siteType,
        date: $date) {
        id,
        searcher {
            name,
            colleagueNumber
        },
        randomiserType,
        colleagueBrand,
        outcome,
        addedAt
        }
    }`

  const { data, loading, error } = useQuery(GET_RANDOMISER_OUTCOMES, {
    variables: {
      siteLocationCode: locationCode, siteCode, siteBrand, siteType, date
    },
    skip: !locationCode || !siteCode || !siteBrand || !siteType || !date
  })

  return {
    data,
    loading,
    error
  }
}

export default useRandomiserOutcomes
