import { useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'

function useProhibitedItemExclusions() {
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const searchType = useSelector((state) => state.search.type)

  const GET_PROHIBITED_ITEM_EXCLUSIONS = gql`query
    prohibitedItemExclusions(
      $siteLocationCode: String!,
      $searchType: String!) {
      prohibitedItemExclusions(
        siteLocationCode: $siteLocationCode,
        searchType: $searchType)  
    }`

  const { data, loading } = useQuery(GET_PROHIBITED_ITEM_EXCLUSIONS, {
    variables: { siteLocationCode: locationCode, searchType }
  })

  return {
    data,
    loading
  }
}

export default useProhibitedItemExclusions
