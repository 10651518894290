/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  azureId: null,
  fullName: null,
  locationCode: null,
  businessUnit: null,
  jobName: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAzureId: (state, action) => {
      state.azureId = action.payload
    },
    setUserFullName: (state, action) => {
      state.fullName = action.payload
    },
    setUserLocationCode: (state, action) => {
      state.locationCode = action.payload
    },
    setUserBusinessUnit: (state, action) => {
      state.businessUnit = action.payload
    },
    setUserJobName: (state, action) => {
      state.jobName = action.payload
    }
  }
})

export const {
  setUserAzureId, setUserFullName, setUserLocationCode, setUserBusinessUnit, setUserJobName
} = userSlice.actions

export default userSlice.reducer
