import React, { useState, useEffect } from 'react'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gql, useLazyQuery } from '@apollo/client'
import { Body1 } from '@jsluna/typography'
import { Alert } from '@jsluna/alert'
import LoadingSpinner from '../Progress/LoadingSpinner'
import SearchCard from './SearchCard'
import { COLLEAGUE } from '../../utils/constants'
import { setSearchType } from '../Search/searchSlice'

function ColleagueSearch() {
  const isSiteEnabledForTrial = useSelector((state) => state.trialSites.isSiteEnabled)
  const isColleagueSearchEnabled = useSelector((state) => state.featureToggle.isColleagueSearchEnabled)
  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const siteCode = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [colleagueCountsData, setColleagueCountsData] = useState(null)

  const GET_COLLEAGUE_COUNTS = gql`query colleagueCounts($siteCode: String!, $siteType: String!, $siteBrand: String!) {
    colleagueCounts(siteCode: $siteCode, siteType: $siteType, siteBrand: $siteBrand) {
      sainsburys,
      argos,
      from,
      to
    }
  }`

  const [getColleagueCounts, { loading }] = useLazyQuery(GET_COLLEAGUE_COUNTS, {
    variables: { }
  })

  useEffect(() => {
    if (siteCode && siteType) {
      getColleagueCounts({ variables: { siteCode, siteType, siteBrand } }).then((response) => {
        setColleagueCountsData(response.data)
      })
    }
  }, [getColleagueCounts, siteCode, siteType, siteBrand])

  if (loading) {
    return <LoadingSpinner />
  }

  const colleagueCounts = ['sainsburys', 'argos'].reduce((result, key) => ({
    ...result,
    [key]: colleagueCountsData?.colleagueCounts[key]
  }), {}) // removes properties from the original object returned from the api so we are left with only sainsburys and argos

  const filteredColleagueCounts = Object.fromEntries(
    Object.entries(colleagueCounts).filter(
      ([_, value]) => value !== null
    )
  ) // excludes items from the object where the value is null so we don't display in the UI on render of SearchCard

  const colleagueDisplayNames = {
    sainsburys: 'Sainsbury\'s',
    argos: 'Argos'
  }

  const gridDefaultSizeColleague = `1/${Object.keys(filteredColleagueCounts).length}`

  const onPersonSearchClick = () => {
    dispatch(setSearchType(COLLEAGUE))
    navigate('/randomiser')
  }

  const onViewSearchHistory = () => {
    navigate('/history')
  }

  if (!isSiteEnabledForTrial || !isColleagueSearchEnabled) {
    return null
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      {colleagueCountsData?.colleagueCounts?.sainsburys === null && colleagueCountsData?.colleagueCounts?.argos === null
        ? (
          <GridItem className="ln-u-margin-top*2 ln-u-hard-left" size={{ default: '1/1' }}>
            <Alert variant="info">
              <div className="ln-u-margin-left">
                <Body1
                  id="no-colleagues-allocated-text"
                  data-testid="no-colleagues-allocated-text"
                >
                  There are currently no colleagues allocated to your store. If you have colleagues in store, please
                  <strong> contact the IT Service Desk </strong>
                  to let us know you&apos;ve got colleagues at your store.
                </Body1>
              </div>
            </Alert>
          </GridItem>
        )
        : (
          <SearchCard
            title="People"
            buttonText="Person search"
            gridDefaultSize={gridDefaultSizeColleague}
            displayNames={colleagueDisplayNames}
            counts={filteredColleagueCounts}
            onClick={() => onPersonSearchClick()}
            displaySecondaryButton
            secondaryButtonText="View search history"
            onSecondaryClick={() => onViewSearchHistory()}
          />
        )}
    </GridWrapper>
  )
}

export default ColleagueSearch
