import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  setIsManualEntry,
  setSearcheeName,
  setColleagueNumber,
  setSearcheeJobRole,
  setBusinessUnit,
  setLocationCode,
  setSearcheeBrand
} from '../features/Searchee/searcheeSlice'
import { setDrivesOrWorksForError, setSearcheeNameError } from '../features/Error/errorSlice'
import useStateCustom from './useStateCustom'
import useLocalStorage from './useLocalStorage'
import {
  SAINSBURYS, ARGOS, HABITAT, RETAIL, LOGISTICS, ARGOS_RETAIL
} from '../utils/constants'

function useSearchee() {
  const [searchValue, setSearchValue] = useState('')
  const { resetSearcheeDetailsState, resetErrors } = useStateCustom()
  const { removeLocationAndBusinessUnit } = useLocalStorage()

  const isManual = useSelector((state) => state.searchee.isManualEntry)
  const name = useSelector((state) => state.searchee.name)
  const colleagueId = useSelector((state) => state.searchee.colleagueNumber)
  const jobRole = useSelector((state) => state.searchee.jobRole)
  const businessUnit = useSelector((state) => state.searchee.businessUnit)
  const locationCode = useSelector((state) => state.searchee.locationCode)
  const displayWorksForError = useSelector((state) => state.error.searchee.drivesOrWorksFor)

  const dispatch = useDispatch()

  const onNameChange = (e) => {
    dispatch(setSearcheeName(e.target.value))
    if (e.target.value !== '') {
      dispatch(setSearcheeNameError(false))
    }
  }

  const onAutoCompleteNameChange = (e) => {
    setSearchValue(e.target.value)
    if (e.target.value !== '') {
      dispatch(setSearcheeNameError(false))
    }
  }

  const getBrand = (colleagueBusinessUnit, location) => {
    if (colleagueBusinessUnit?.toLowerCase()?.includes(ARGOS.toLowerCase())) {
      if (colleagueBusinessUnit?.toLowerCase() === ARGOS_RETAIL && location?.toLowerCase().startsWith('l')) {
        return SAINSBURYS
      }

      return ARGOS
    }

    if (location?.toLowerCase().startsWith('h')) {
      return HABITAT
    }

    if ((colleagueBusinessUnit?.toLowerCase() === RETAIL || colleagueBusinessUnit.toLowerCase() === LOGISTICS) && location?.toLowerCase().startsWith('l')) {
      return SAINSBURYS
    }

    return ''
  }

  const onSelectName = (e) => {
    if (e) {
      dispatch(setSearcheeName(e?.value?.split('|')[0]))
      dispatch(setColleagueNumber(e?.value?.split('|')[1].replace('(', '').replace(')', '')))
      dispatch(setSearcheeJobRole(e?.value?.split('|')[2]))
      const searcheeBusinessUnit = e?.value?.split('|')[3]
      const searcheeLocationCode = e?.value?.split('|')[4]
      const brand = getBrand(searcheeBusinessUnit, searcheeLocationCode)
      dispatch(setSearcheeBrand(brand))
      dispatch(setBusinessUnit(searcheeBusinessUnit))
      window.localStorage.setItem('searcheeBusinessUnit', searcheeBusinessUnit)
      dispatch(setLocationCode(searcheeLocationCode))
      window.localStorage.setItem('searcheeLocationCode', searcheeLocationCode)
      dispatch(setSearcheeNameError(false))
      if (displayWorksForError === true) {
        dispatch(setDrivesOrWorksForError(false))
      }
    } else {
      setSearchValue('')
      dispatch(setSearcheeName(''))
      dispatch(setColleagueNumber(''))
      dispatch(setSearcheeJobRole(''))
      dispatch(setSearcheeBrand(''))
      dispatch(setBusinessUnit(''))
      dispatch(setLocationCode(''))
      removeLocationAndBusinessUnit()
    }
  }

  const getSelectedNameOption = () => {
    const option = {
      label: `${name} (${colleagueId})`,
      value: `${name}|(${colleagueId})|${jobRole}|${businessUnit}|${locationCode}`
    }

    return option
  }

  const getDefaultSelectedOption = () => {
    if (name !== '' && colleagueId !== '' && jobRole !== '' && businessUnit !== '' && locationCode !== '') {
      return getSelectedNameOption()
    }

    return undefined
  }

  const onHyperlinkClick = (e) => {
    e.preventDefault()
    dispatch(setIsManualEntry(!isManual))
    window.localStorage.setItem('searcheeAddedManually', !isManual)
    if (window.localStorage.getItem('pageRefreshed') === 'true') {
      const isManualEntry = window.localStorage.getItem('searcheeAddedManually') === 'true'
      window.localStorage.setItem('searcheeAddedManually', !isManualEntry)
      dispatch(setIsManualEntry(!isManualEntry))
      window.localStorage.setItem('pageRefreshed', 'false')
    }
    resetSearcheeDetailsState()
    resetErrors()
    setSearchValue('')
  }

  return {
    onNameChange,
    onAutoCompleteNameChange,
    onSelectName,
    getDefaultSelectedOption,
    onHyperlinkClick,
    searchValue
  }
}

export default useSearchee
