import {
  SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
} from '../constants'

const getDay = (date) => {
  const days = [
    SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY
  ]

  return days[(new Date(date))?.getDay()]
}

export default getDay
