/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3, Display2 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { Button, FilledButton } from '@jsluna/button'
import { decrement, increment } from '../Progress/progressSlice'

function RefusedSearchProcedures() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const progress = useSelector((state) => state.progress.fill)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  window.onpopstate = () => {
    if (progress >= 1) {
      dispatch(decrement())
    } else {
      dispatch(increment())
    }
  }

  const onBack = () => {
    dispatch(decrement())
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/selection')
  }

  const onStillRefusedSearchClick = () => {
    dispatch(increment())
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/refused/reason')
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2 ln-u-margin-left*2 ln-u-margin-top" size={{ default: '1/1' }}>
        <Display3 id="refused-procedures-title" data-testid="refused-procedures-title">Refused search</Display3>
      </GridItem>
      <Card>
        <GridItem className="ln-u-hard-left ln-u-margin-top ln-u-margin-bottom*2" size={{ default: '1/1' }}>
          <Display2 id="refused-procedures-subtitle">Advice and procedures</Display2>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <ul>
            <li id="procedure-point-1" className="ln-u-margin-bottom*2">Remind the colleague of our policy that we can search colleagues and visitors on our premises</li>
            <li id="procedure-point-2" className="ln-u-margin-bottom*2">If the colleagues shift has finished or they are on a break, remind the colleague we would pay them/extend their break by the time taken to wait and then conduct the search</li>
            <li id="procedure-point-3" className="ln-u-margin-bottom*2">In the case that the colleague still refuses the search, you can, if required, suspend the colleague.</li>
          </ul>
        </GridItem>
        <GridItem className="ln-u-hard-left ln-u-margin-top" size={{ default: '1/4' }}>
          <div className="ln-u-margin-right">
            <Button id="back-btn" fullWidth onClick={onBack}>Back</Button>
          </div>
        </GridItem>
        <GridItem className="ln-u-hard-left ln-u-margin-top" size={{ default: '3/4' }}>
          <div className="ln-u-margin-left">
            <FilledButton id="still-refused-btn" fullWidth onClick={onStillRefusedSearchClick}>Still refused search</FilledButton>
          </div>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default RefusedSearchProcedures
