import React from 'react'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Button, FilledButton } from '@jsluna/button'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import useStateCustom from '../../customHooks/useStateCustom'
import Result from './Result'
import { setRandomiserOutcomeId, setRandomiserOutcome } from './randomiserSlice'
import { setColleagueNameError } from '../Error/errorSlice'
import isFullNameValid from '../../utils/helper/validationHelper'

function RandomiserOutcome() {
  const { resetRandomiserAndNameState } = useStateCustom()
  const dispatch = useDispatch()
  const colleagueName = useSelector((state) => state.randomiser.colleagueName)
  const siteLocationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteName = useSelector((state) => state.site.selected.name)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const searchType = useSelector((state) => state.search.type)
  const randomiserOutcome = useSelector((state) => state.randomiser.outcome)

  const GET_RANDOMISER_OUTCOME = gql`query randomiserOutcome(
    $siteLocationCode: String!, 
    $searchType: String!,
    $selectedSiteCode: String,
    $selectedSiteName: String,
    $selectedSiteBrand: String,
    $selectedSiteType: String,
    $colleagueName: String!
    ) {
    randomiserOutcome(
      siteLocationCode: $siteLocationCode, 
      searchType: $searchType,
      selectedSite: {
        code: $selectedSiteCode,
        name: $selectedSiteName,
        brand: $selectedSiteBrand,
        type: $selectedSiteType
      },
      colleagueName: $colleagueName
      ) {
      id,
      outcome
    }
  }`

  const [getRandomiserOutcome, { loading, error }] = useLazyQuery(GET_RANDOMISER_OUTCOME, {
    variables: {}
  })

  const onStartRandomiser = () => {
    if (!isFullNameValid(colleagueName)) {
      dispatch(setColleagueNameError(true))
    }
    if (siteLocationCode !== null && searchType !== null && isFullNameValid(colleagueName)) {
      getRandomiserOutcome({
        variables: {
          siteLocationCode,
          searchType,
          selectedSiteCode,
          selectedSiteName,
          selectedSiteBrand,
          selectedSiteType,
          colleagueName
        }
      }).then((response) => {
        dispatch(setRandomiserOutcomeId(response.data.randomiserOutcome.id))
        dispatch(setRandomiserOutcome(response.data.randomiserOutcome.outcome))
      })
    }
  }

  if (error) {
    console.log('error getting randomiser outcome: ', error)
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-top*3 ln-u-margin-bottom*2">
        <FilledButton
          className="ln-u-padding*4"
          id="start-randomiser-btn"
          fullWidth
          onClick={() => onStartRandomiser()}
          disabled={loading || randomiserOutcome !== null}
        >
          Start Randomiser
        </FilledButton>
      </GridItem>
      <Result title="Randomiser result" loading={loading} error={error} />
      <GridItem className="ln-u-hard-left">
        <Button id="reset-btn" data-testid="reset-btn" className="ln-u-margin-bottom*4" fullWidth onClick={() => resetRandomiserAndNameState()}>Reset</Button>
      </GridItem>
    </GridWrapper>
  )
}

export default RandomiserOutcome
