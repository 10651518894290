import React, { useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3, Display1, Body1 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { List } from '@jsluna/list'
import {
  Button, FilledButton, OutlinedButton, LinkButton
} from '@jsluna/button'
import { Alert } from '@jsluna/alert'
import LoadingSpinner from '../Progress/LoadingSpinner'
import { decrement, increment, reset } from '../Progress/progressSlice'
import { setSearchStatus } from '../Search/searchSlice'
import { setRecentlyAddedItem, setItems, setRecentlyRemovedItem } from './itemsSlice'
import {
  GOL, FAST_TRACK, DRAFT, ITEMS_FOUND, COLLEAGUE, VAN, SAINSBURYS, ARGOS
} from '../../utils/constants'
import useLocalStorage from '../../customHooks/useLocalStorage'

function ItemsFound() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { removeLocationAndBusinessUnit } = useLocalStorage()

  const items = useSelector((state) => state.items.items)
  const recentlyAdded = useSelector((state) => state.items.recentlyAdded)
  const recentlyRemoved = useSelector((state) => state.items.recentlyRemoved)
  const searchStatus = useSelector((state) => state.search.status)
  const allowBrowserBack = useSelector((state) => state.progress.allowBrowserBack)
  const progress = useSelector((state) => state.progress.fill)
  const id = useSelector((state) => state.search.id)
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const siteNumber = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType
  const drivesFor = useSelector((state) => state.searchee.drivesFor)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const searchType = useSelector((state) => state.search.type)
  const searcheeBrand = useSelector((state) => state.searchee.brand)

  const searchTypeDisplayName = searchType === COLLEAGUE ? 'on person' : 'in van'

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (searchStatus !== DRAFT || !allowBrowserBack) {
      navigate('/')
    }
  }, [searchStatus, allowBrowserBack, navigate])

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  const FIELD_NAMES = ['SearchStatus']

  const SEARCH = gql`mutation updateSearch (
    $siteLocationCode: String!,
    $searchId: String!,
    $fieldNames: [String!]!,
    $searchStatus: String!,
    ) {
    updateSearch(
      siteLocationCode: $siteLocationCode,
      id: $searchId,
      fieldNames: $fieldNames,
      search: {
      id: "",
      searchStatus: $searchStatus,
      site: {
        primaryAssigned: {},
        selected: {}
      },
      searcher: {},
      searchee: {},
      observer: {},
      items: []
    }) {
      id
    }
  }`

  const [searchCompleteUpdate] = useMutation(SEARCH)

  const FIELD_NAME_FOR_ITEMS = [
    'Items'
  ]

  const SEARCH_ITEMS = gql`mutation updateItems (
    $siteLocationCode: String!,
    $searchId: String!,
    $fieldNames: [String!]!,
    $items: [ItemInput!]!
    ) {
    updateItems(
      siteLocationCode: $siteLocationCode,
      id: $searchId,
      fieldNames: $fieldNames,
      itemsInput: $items,
      search: {
      id: $searchId,
      site: {
        primaryAssigned: {},
        selected: {}
      },
      searcher: {},
      searchee: {},
      observer: {},
      items: $items
    }) {
      id
    }
  }`

  const [updateSearchItems] = useMutation(SEARCH_ITEMS)

  const GET_VAN_COUNTS = gql`query vanCounts($siteCode: String!, $siteBrand: String!, $siteType: String!) {
    vanCounts(siteCode: $siteCode, siteBrand: $siteBrand, siteType: $siteType) {
      gol,
      fastTrack,
      from,
      to
    }
  }`

  const GET_COLLEAGUE_COUNTS = gql`query colleagueCounts($siteCode: String!, $siteType: String!, $siteBrand: String!) {
    colleagueCounts(siteCode: $siteCode, siteType: $siteType, siteBrand: $siteBrand) {
      sainsburys,
      argos,
      from,
      to
    }
  }`

  const [getVanCounts, { loading }] = useLazyQuery(GET_VAN_COUNTS, {
    variables: { }
  })

  const [getColleagueCounts] = useLazyQuery(GET_COLLEAGUE_COUNTS, {
    variables: { }
  })

  const data = useRef()

  const colleagueData = useRef()
  useEffect(() => {
    if (siteNumber && siteType && siteBrand) {
      getVanCounts({ variables: { siteCode: siteNumber, siteBrand, siteType } }).then((response) => {
        data.current = response.data
      })
    }
  }, [getVanCounts, siteNumber, siteType, siteBrand])

  useEffect(() => {
    if (siteNumber && siteType) {
      getColleagueCounts({ variables: { siteCode: siteNumber, siteType, siteBrand } }).then((response) => {
        colleagueData.current = response.data
      })
    }
  }, [getColleagueCounts, siteNumber, siteType, siteBrand])

  if (loading) {
    return <LoadingSpinner />
  }

  const getRemainingVanCount = () => {
    if (drivesFor === GOL) {
      if (data?.current?.vanCounts?.gol > 1) {
        const golCount = data?.current?.vanCounts?.gol
        return golCount - 1
      }

      return 'No'
    }

    if (drivesFor === FAST_TRACK) {
      if (data?.current?.vanCounts?.fastTrack > 1) {
        const fastTrackCount = data?.current?.vanCounts?.fastTrack
        return fastTrackCount - 1
      }

      return 'No'
    }

    return ''
  }

  const getRemainingColleagueCount = () => {
    if (searcheeBrand === SAINSBURYS) {
      if (colleagueData?.current?.colleagueCounts?.sainsburys > 1) {
        const sainsburysCount = colleagueData?.current?.colleagueCounts?.sainsburys
        return sainsburysCount - 1
      }
      return 'No'
    }

    if (searcheeBrand === ARGOS) {
      if (colleagueData?.current?.colleagueCounts?.argos > 1) {
        const argosCount = colleagueData?.current?.colleagueCounts?.argos
        return argosCount - 1
      }
      return 'No'
    }
    return ''
  }

  window.onpopstate = () => {
    if (searchStatus === DRAFT) {
      if (progress < 1) {
        dispatch(increment())
      } else {
        dispatch(decrement())
        dispatch(setRecentlyRemovedItem(false))
      }
    } else {
      navigate('/')
    }
  }

  const onBack = () => {
    dispatch(decrement())
    dispatch(setRecentlyRemovedItem(false))
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/selection')
  }

  const onAddItem = () => {
    dispatch(increment())
    dispatch(setRecentlyAddedItem(false))
    dispatch(setRecentlyRemovedItem(false))
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/items/add')
  }

  const onRemove = (itemId) => {
    dispatch(setRecentlyAddedItem(false))
    const updatedItems = [...items].filter((item) => item.id !== itemId)
    dispatch(setItems(updatedItems))
    dispatch(setRecentlyRemovedItem(true))
    updateSearchItems({
      variables: {
        siteLocationCode: locationCode, searchId: id, fieldNames: FIELD_NAME_FOR_ITEMS, items: updatedItems
      }
    }).catch((err) => {
      console.log(`Error updating search items on remove | id ${id} | ${err}`)
      dispatch(reset())
      navigate('/error')
    })
  }

  const onFinish = () => {
    dispatch(reset())
    window.localStorage.removeItem('searchId')
    removeLocationAndBusinessUnit()
    window.localStorage.setItem('pageRefreshed', 'false')
    dispatch(setRecentlyAddedItem(false))
    dispatch(setRecentlyRemovedItem(false))
    dispatch(setSearchStatus(ITEMS_FOUND))
    searchCompleteUpdate({
      variables: {
        siteLocationCode: locationCode, searchId: id, fieldNames: FIELD_NAMES, searchStatus: ITEMS_FOUND
      }
    }).catch((err) => {
      if (err.networkError.toString().includes('403')) {
        navigate('/forbidden')
      } else {
        console.log(`Error updating search document with items found status | id ${id} | ${err}`)
        dispatch(reset())
        navigate('/error')
      }
    })

    if (searchType === VAN) {
      navigate('/outcome', { state: { title: 'Search completed', getRemainingCount: `${getRemainingVanCount()}`, outcomeType: drivesFor } })
    }

    if (searchType === COLLEAGUE) {
      navigate('/outcome', {
        state: {
          title: 'Search completed',
          getRemainingCount: `${getRemainingColleagueCount()}`,
          outcomeType: searcheeBrand === 'Sainsburys' ? 'Sainsbury\'s' : searcheeBrand
        }
      })
    }
  }

  function padWithTrailingZeros(itemPrice) {
    let pence = 0

    if (itemPrice.includes('.')) {
      pence = itemPrice?.split('.')[1]
    }

    if (pence === 0) {
      return itemPrice.padEnd(itemPrice.length + 3, '.00')
    }

    if (pence.length === 0) {
      return itemPrice.padEnd(itemPrice.length + 2, 0)
    }

    if (pence.length === 1) {
      return itemPrice.padEnd(itemPrice.length + 1, 0)
    }

    return itemPrice.padEnd(itemPrice.length, 0)
  }

  const guidanceList = [
    'Ask the person to explain why they have it and where it came from.',
    'Identify if we sell the item and ask for proof of purchase or receipt, if we do.',
    <li>
      If it’s obvious the item is company property, then contact
      {' '}
      <b>Business Protection</b>
      {' '}
      for advice.
    </li>
  ]

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2 ln-u-margin-top" size={{ default: '1/1' }}>
        <Display3 id="title" className="ln-u-margin-left*2">{`Items found ${searchTypeDisplayName}`}</Display3>
      </GridItem>
      {(recentlyAdded === true || recentlyRemoved === true) && (
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Alert id="success-alert" variant="success">
            <div className="ln-u-margin-left" id="sucess-alert-message" data-testid="sucess-alert-message">
              {recentlyAdded ? 'Item added' : 'Item removed'}
            </div>
          </Alert>
        </GridItem>
      )}
      <Card>
        {items.length === 0 ? (
          <>
            <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
              <Display1 id="subtitle" data-testid="subtitle">
                Has anything been found?
              </Display1>
            </GridItem>
            <GridItem className="ln-u-hard-left ln-u-margin-top ln-u-margin-bottom" size={{ default: '1/1' }}>
              <Body1 id="text" data-testid="text">If anything is found during the search, then this must be dealt with correctly:</Body1>
              <List
                className="ln-u-margin-left*3"
                id="list"
                data-testid="list"
                items={guidanceList}
              />
            </GridItem>
            <GridItem className="ln-u-hard-left ln-u-margin-top" size={{ default: '1/1' }}>
              <FilledButton id="add-item-btn" fullWidth onClick={onAddItem}>Add an item</FilledButton>
            </GridItem>
            <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
              <Button id="back-btn" className="ln-u-margin-top*4" fullWidth onClick={onBack}>Back</Button>
            </GridItem>
          </>
        ) : (
          <>
            <GridItem className="ln-u-hard-left ln-u-margin-top ln-u-margin-bottom*3" size={{ default: '1/1' }}>
              <OutlinedButton id="add-another-item-btn" fullWidth onClick={onAddItem}>Add another item</OutlinedButton>
            </GridItem>
            {
              items.map((item) => ((
                <div key={`div-${item.id}`} id={item.id}>
                  <GridItem
                    className="ln-u-margin-bottom"
                    size={{ default: '1/1' }}
                  >
                    <Display1
                      id={`item-name-${item.id}`}
                      data-testid={`item-name-${item.id}`}
                    >
                      {`Item: ${item.name}`}
                    </Display1>
                  </GridItem>
                  <GridItem
                    size={{ default: '1/1' }}
                  >
                    <Body1
                      id={`item-price-${item.id}`}
                      data-testid={`item-price-${item.id}`}
                    >
                      {item.price === null ? 'Price: -' : `Price: £${padWithTrailingZeros(item.price.toString())}`}
                    </Body1>
                  </GridItem>
                  <GridItem
                    size={{ default: '1/1' }}
                  >
                    <Body1
                      id={`item-location-${item.id}`}
                      data-testid={`item-location-${item.id}`}
                    >
                      {`Location: ${item.location}`}
                    </Body1>
                  </GridItem>
                  <GridItem
                    size={{ default: '1/1' }}
                  >
                    <Body1
                      id={`item-reason-${item.id}`}
                      data-testid={`item-reason-${item.id}`}
                    >
                      {`Reason: ${item.reason}`}
                    </Body1>
                  </GridItem>
                  <GridItem
                    className="ln-u-margin-top ln-u-margin-bottom*3"
                    size={{ default: '1/1' }}
                  >
                    <LinkButton
                      id={`remove-link-${item.id}`}
                      data-testid={`remove-link-${item.id}`}
                      className="c-items-found-remove-link"
                      onClick={() => onRemove(item.id)}
                    >
                      Remove
                    </LinkButton>
                  </GridItem>
                </div>
              )))
            }
            <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
              <FilledButton id="finish-btn" fullWidth onClick={onFinish}>Finish</FilledButton>
            </GridItem>
          </>
        )}
      </Card>
    </GridWrapper>
  )
}

export default ItemsFound
