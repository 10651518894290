import React from 'react'
import { Card } from '@jsluna/card'
import { Display3, Body1 } from '@jsluna/typography'
import { Link } from '@jsluna/link'

function Forbidden() {
  return (
    <Card>
      <Display3>Access denied</Display3>
      <div className="ln-u-margin-top*2">
        <Body1>
          Searches should be completed by a Manager. If you are a Manager and cannot access this app please contact STSD on
          {' '}
          <Link
            data-testid="phone-number-link"
            href="tel:03456032638"
            cta
            bare
          >
            0345 603 2638
          </Link>
          .
        </Body1>
      </div>
    </Card>
  )
}

export default Forbidden
