import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ButtonText as Label1 } from '@jsluna/typography'
import {
  SAINSBURYS,
  ARGOS,
  COLLEAGUE_SEARCH,
  RANDOM,
  ITEMS_FOUND,
  PERSONAL,
  LOCKER,
  ATM_CASH_OFFICE,
  VISITOR
} from '../../utils/constants'
import SearchTypeCountView from './SearchTypeCountView'

function SearchesCompletedInWeek({ randomiserOutcomesData, searchesData, brand }) {
  const getItemsFoundCount = (colleagueSearches) => (
    colleagueSearches
      ?.filter((x) => x?.searchStatus === ITEMS_FOUND)
      ?.map((x) => (x?.items?.length))
      ?.reduce((sum, num) => sum + num, 0) ?? 0
  )

  let randomiserOutcomes = randomiserOutcomesData?.allRandomiserOutcomes
    .filter((x) => x?.outcome === false && x?.randomiserType === COLLEAGUE_SEARCH)

  let searches = searchesData?.allCompletedSearches?.filter((x) => x?.searchee?.brand !== VISITOR)

  if (brand === SAINSBURYS.toLowerCase() || brand === ARGOS.toLowerCase()) {
    searches = searches?.filter((x) => x?.searchee?.brand?.toLowerCase() === brand)
    randomiserOutcomes = randomiserOutcomes?.filter((x) => x?.colleagueBrand?.toLowerCase() === brand)
  }

  const randomSearches = searches?.filter((x) => x?.searchSubtype === RANDOM)
  const randomSearchItemsFound = getItemsFoundCount(randomSearches)

  const specificPersonalSearches = searches?.filter((x) => x?.searchSubtype === PERSONAL)
  const specificPersonalSearchItemsFound = getItemsFoundCount(specificPersonalSearches)

  const lockerRoomSearches = searches?.filter((x) => x?.searchSubtype === LOCKER)
  const lockerRoomSearchItemsFound = getItemsFoundCount(lockerRoomSearches)

  const atmCashOfficeSearches = searches?.filter((x) => x?.searchSubtype === ATM_CASH_OFFICE)
  const atmCashOfficeSearchItemsFound = getItemsFoundCount(atmCashOfficeSearches)

  const completedSearchesView = [
    {
      searchType: 'Standard random search (search needed)',
      searchCount: randomSearches.length,
      viewItemsFoundCount: true,
      itemsFoundCount: randomSearchItemsFound,
      id: 'random-search'
    },
    {
      searchType: 'Standard random search (no search needed)',
      searchCount: randomiserOutcomes.length,
      viewItemsFoundCount: false,
      itemsFoundCount: 0,
      id: 'random-no-search'
    },
    {
      searchType: 'Specific personal search',
      searchCount: specificPersonalSearches.length,
      viewItemsFoundCount: true,
      itemsFoundCount: specificPersonalSearchItemsFound,
      id: 'personal-search'
    },
    {
      searchType: 'Locker room search',
      searchCount: lockerRoomSearches.length,
      viewItemsFoundCount: true,
      itemsFoundCount: lockerRoomSearchItemsFound,
      id: 'locker-search'
    },
    {
      searchType: 'ATM room / cash office search',
      searchCount: atmCashOfficeSearches.length,
      viewItemsFoundCount: true,
      itemsFoundCount: atmCashOfficeSearchItemsFound,
      id: 'atm-cash-office-search'
    }
  ]

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <Label1
          id="searches-completed-this-week"
        >
          Searches completed this week
        </Label1>
      </GridItem>
      {completedSearchesView.map((x) => (
        <SearchTypeCountView
          searchType={x.searchType}
          searchCount={x.searchCount}
          viewItemsFoundCount={x.viewItemsFoundCount}
          itemsFoundCount={x.itemsFoundCount}
          id={x.id}
        />
      ))}
    </GridWrapper>
  )
}

SearchesCompletedInWeek.propTypes = {
  randomiserOutcomesData: PropTypes.node.isRequired,
  searchesData: PropTypes.node.isRequired,
  brand: PropTypes.string.isRequired
}

export default SearchesCompletedInWeek
