import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { reset, increment } from '../features/Progress/progressSlice'
import {
  setObserverNameError,
  setCctvCapturedError,
  setCompanyNameError,
  setSearchSubtypeError,
  setDrivesOrWorksForError,
  setSearcheeNameError
} from '../features/Error/errorSlice'
import { VAN, COLLEAGUE, VISITOR } from '../utils/constants'

function useSearcheeContinue() {
  const isManual = useSelector((state) => state.searchee.isManualEntry)
  const name = useSelector((state) => state.searchee.name)
  const colleagueId = useSelector((state) => state.searchee.colleagueNumber)
  const drivesFor = useSelector((state) => state.searchee.drivesFor)
  const jobRole = useSelector((state) => state.searchee.jobRole)
  const searcheeBrand = useSelector((state) => state.searchee.brand)
  const siteLocationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const searchId = useSelector((state) => state.search.id)
  const searchType = useSelector((state) => state.search.type)
  const searchSubtype = useSelector((state) => state.search.subtype)
  const cctvCaptured = useSelector((state) => state.search.cctvCaptured)
  const observerName = useSelector((state) => state.observer.name)
  const searcheeType = useSelector((state) => state.searchee.type)
  const visitorCompany = useSelector((state) => state.searchee.visitorCompany)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const FIELD_NAMES = [
    'Searchee.Name',
    'Searchee.AddedManually',
    'Searchee.DrivesFor',
    'Searchee.ColleagueNumber',
    'Searchee.JobRole',
    'Searchee.Brand',
    'Searchee.Type',
    'Searchee.VisitorCompany',
    'SearchSubtype',
    'CctvCaptured',
    'Observer.Name'
  ]

  const SEARCH = gql`mutation updateSearch (
        $siteLocationCode: String!,
        $searchId: String!,
        $fieldNames: [String!]!,
        $searcheeName: String!,
        $addedManually: Boolean!,
        $drivesFor: String,
        $colleagueId: String,
        $jobRole: String,
        $brand: String,
        $searcheeType: String,
        $visitorCompany: String,
        $searchSubtype: String,
        $cctvCaptured: Boolean,
        $observerName: String 
        ) {
        updateSearch(
          siteLocationCode: $siteLocationCode,
          id: $searchId,
          fieldNames: $fieldNames,
          search: {
          id: "",
          searchSubtype: $searchSubtype,
          cctvCaptured: $cctvCaptured,
          site: {
            primaryAssigned: {},
            selected: {}
          },
          searcher: {},
          searchee: {
            name: $searcheeName,
            addedManually: $addedManually,
            drivesFor: $drivesFor,
            colleagueNumber: $colleagueId,
            jobRole: $jobRole,
            brand: $brand,
            type: $searcheeType,
            visitorCompany: $visitorCompany
          },
          observer: {
            name: $observerName
          }
          items: []
        }) {
          id
        }
      }`

  const [amendSearch] = useMutation(SEARCH)

  const onContinueClick = () => {
    if (!name) {
      dispatch(setSearcheeNameError(true))
    }

    if ((!drivesFor && searchType === VAN) || (!searcheeBrand && searchType === COLLEAGUE)) {
      dispatch(setDrivesOrWorksForError(true))
    }

    if (!searchSubtype && searchType === COLLEAGUE) {
      dispatch(setSearchSubtypeError(true))
    }

    if (!cctvCaptured && searchType === COLLEAGUE) {
      dispatch(setCctvCapturedError(true))
    }

    if (cctvCaptured === false && !observerName && searchType === COLLEAGUE) {
      dispatch(setCctvCapturedError(false))
      dispatch(setObserverNameError(true))
    }

    if (cctvCaptured === false && observerName && searchType === COLLEAGUE) {
      dispatch(setCctvCapturedError(false))
    }

    if (searcheeType === VISITOR && !visitorCompany && isManual === true && searchType === COLLEAGUE) {
      dispatch(setCompanyNameError(true))
    }

    if ((name && drivesFor && searchType === VAN)
        || (name && searcheeBrand && searchSubtype && cctvCaptured === true && searcheeType === VISITOR && visitorCompany && searchType === COLLEAGUE)
        || (name && searcheeBrand && searchSubtype && cctvCaptured === false && observerName && searcheeType !== VISITOR && searchType === COLLEAGUE)
        || (name && searcheeBrand && searchSubtype && cctvCaptured === true && searcheeType !== VISITOR && searchType === COLLEAGUE)
        || (name && searcheeBrand && searchSubtype && cctvCaptured === false && observerName && searcheeType === VISITOR && visitorCompany && searchType === COLLEAGUE)) {
      amendSearch({
        variables: {
          siteLocationCode,
          searchId,
          fieldNames: FIELD_NAMES,
          searcheeName: name,
          addedManually: isManual,
          drivesFor,
          colleagueId,
          jobRole,
          brand: searcheeBrand,
          searcheeType,
          visitorCompany,
          searchSubtype,
          cctvCaptured,
          observerName
        }
      }).catch((err) => {
        if (err.networkError.toString().includes('403')) {
          navigate('/forbidden')
        } else {
          console.log(`Error updating search document with id ${searchId} | ${err}`)
          dispatch(reset())
          window.localStorage.removeItem('progressFill')
          navigate('/error')
        }
      })
      window.localStorage.setItem('pageRefreshed', 'false')
      navigate('/selection')
      dispatch(increment())
    }
  }

  return {
    onContinueClick
  }
}

export default useSearcheeContinue
