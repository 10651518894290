import React from 'react'
import { ProgressIndicator, ProgressSpinner } from '@jsluna/progress'

function LoadingSpinner() {
  return (
    <ProgressIndicator
      id="progress-indicator"
      className="ln-u-justify-content-center"
      loading
      page
      preventFocus
    >
      <ProgressSpinner id="loading-spinner" size="large" />
    </ProgressIndicator>

  )
}

export default LoadingSpinner
