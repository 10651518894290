import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useMutation, useLazyQuery } from '@apollo/client'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { TextAreaField } from '@jsluna/form'
import { Button, FilledButton } from '@jsluna/button'
import LoadingSpinner from '../Progress/LoadingSpinner'
import { decrement, reset, increment } from '../Progress/progressSlice'
import { setSearchStatus, setRefusedSearchReason } from '../Search/searchSlice'
import useStateCustom from '../../customHooks/useStateCustom'
import useLocalStorage from '../../customHooks/useLocalStorage'
import {
  GOL, FAST_TRACK, VAN, COLLEAGUE, SAINSBURYS, ARGOS, DRAFT, REFUSED
} from '../../utils/constants'

function RefusedSearchReason() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { resetSearcheeDetailsState, resetSearchState } = useStateCustom()
  const { removeLocationAndBusinessUnit } = useLocalStorage()

  const refusedSearchReason = useSelector((state) => state.search.refusedReason)

  const [error, setError] = useState('')
  const [disabled, setDisabled] = useState(false)

  const searchId = useSelector((state) => state.search.id)
  const searchStatus = useSelector((state) => state.search.status)
  const allowBrowserBack = useSelector((state) => state.progress.allowBrowserBack)
  const progress = useSelector((state) => state.progress.fill)
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const siteNumber = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType
  const drivesFor = useSelector((state) => state.searchee.drivesFor)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const searcheeBrand = useSelector((state) => state.searchee.brand)
  const searchType = useSelector((state) => state.search.type)

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  const FIELD_NAMES = [
    'SearchStatus',
    'RefusedSearchReason'
  ]

  useEffect(() => {
    if (searchStatus !== DRAFT || !allowBrowserBack) {
      navigate('/')
    }
  }, [searchStatus, allowBrowserBack, navigate])

  const SEARCH = gql`mutation updateSearch (
    $siteLocationCode: String!,
    $searchId: String!,
    $fieldNames: [String!]!,
    $searchStatus: String!,
    $refusedReason: String!
    ) {
    updateSearch(
      siteLocationCode: $siteLocationCode,
      id: $searchId,
      fieldNames: $fieldNames,
      search: {
      id: "",
      searchStatus: $searchStatus,
      refusedSearchReason: $refusedReason,
      site: {
        primaryAssigned: {},
        selected: {}
      },
      searcher: {},
      searchee: {},
      observer: {},
      items: []
    }) {
      id
    }
  }`

  const [amendSearch] = useMutation(SEARCH)

  const GET_VAN_COUNTS = gql`query vanCounts($siteCode: String!, $siteBrand: String!, $siteType: String!) {
    vanCounts(siteCode: $siteCode, siteBrand: $siteBrand, siteType: $siteType) {
      gol,
      fastTrack,
      from,
      to
    }
  }`

  const GET_COLLEAGUE_COUNTS = gql`query colleagueCounts($siteCode: String!, $siteType: String!, $siteBrand: String!) {
    colleagueCounts(siteCode: $siteCode, siteType: $siteType, siteBrand: $siteBrand) {
      sainsburys,
      argos,
      from,
      to
    }
  }`

  const [getVanCounts, { loading }] = useLazyQuery(GET_VAN_COUNTS, {
    variables: { }
  })

  const [getColleagueCounts] = useLazyQuery(GET_COLLEAGUE_COUNTS, {
    variables: { }
  })

  const data = useRef()

  const colleagueData = useRef()

  useEffect(() => {
    if (siteNumber && siteType && siteBrand) {
      getVanCounts({ variables: { siteCode: siteNumber, siteBrand, siteType } }).then((response) => {
        data.current = response.data
      })
    }
  }, [getVanCounts, siteNumber, siteType, siteBrand])

  useEffect(() => {
    if (siteNumber && siteType) {
      getColleagueCounts({ variables: { siteCode: siteNumber, siteType, siteBrand } }).then((response) => {
        colleagueData.current = response.data
      })
    }
  }, [getColleagueCounts, siteNumber, siteType, siteBrand])

  if (loading) {
    return <LoadingSpinner />
  }

  const getRemainingVanCount = () => {
    if (drivesFor === GOL) {
      if (data?.current?.vanCounts?.gol > 0) {
        const golCount = data?.current?.vanCounts?.gol
        return golCount
      }

      return 'No'
    }

    if (drivesFor === FAST_TRACK) {
      if (data?.current?.vanCounts?.fastTrack > 0) {
        const fastTrackCount = data?.current?.vanCounts?.fastTrack
        return fastTrackCount
      }

      return 'No'
    }

    return ''
  }

  const getRemainingColleagueCount = () => {
    if (searcheeBrand === SAINSBURYS) {
      if (colleagueData?.current?.colleagueCounts?.sainsburys > 0) {
        const sainsburysCount = colleagueData?.current?.colleagueCounts?.sainsburys
        return sainsburysCount - 1
      }
      return 'No'
    }

    if (searcheeBrand === ARGOS) {
      if (colleagueData?.current?.colleagueCounts?.argos > 0) {
        const argosCount = colleagueData?.current?.colleagueCounts?.argos
        return argosCount - 1
      }
      return 'No'
    }
    return ''
  }

  const onRefusedReasonChange = (event) => {
    dispatch(setRefusedSearchReason(event.target.value))
    setError('')
  }

  window.onpopstate = () => {
    if (searchStatus === DRAFT) {
      dispatch(increment())
    } else {
      resetSearcheeDetailsState()
      resetSearchState()
      navigate('/')
    }
  }

  const onBack = () => {
    dispatch(decrement())
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/refused/procedures')
  }

  const onFinish = () => {
    if (!refusedSearchReason) {
      setError('Enter why search was refused')
    } else {
      setDisabled(true)
      dispatch(reset())
      dispatch(setSearchStatus(REFUSED))
      dispatch(setRefusedSearchReason(null))
      window.localStorage.removeItem('progressFill')
      removeLocationAndBusinessUnit()
      window.localStorage.setItem('pageRefreshed', 'false')
      amendSearch({
        variables: {
          siteLocationCode: locationCode,
          searchId,
          fieldNames: FIELD_NAMES,
          searchStatus: REFUSED,
          refusedReason: refusedSearchReason
        }
      }).catch((err) => {
        if (err.networkError.toString().includes('403')) {
          navigate('/forbidden')
        } else {
          console.log(`Error updating search document with refused reason | id ${searchId} | ${err}`)
          navigate('/error')
        }
      })

      if (searchType === VAN) {
        navigate('/outcome', { state: { title: 'Search completed', getRemainingCount: `${getRemainingVanCount()}`, outcomeType: drivesFor } })
      }
      if (searchType === COLLEAGUE) {
        navigate('/outcome', {
          state: {
            title: 'Search completed',
            getRemainingCount: `${getRemainingColleagueCount()}`,
            outcomeType: searcheeBrand === 'Sainsburys' ? 'Sainsbury\'s' : searcheeBrand
          }
        })
      }
    }
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2 ln-u-margin-left*2 ln-u-margin-top" size={{ default: '1/1' }}>
        <Display3 id="refused-reason-title" data-testid="refused-reason-title">Refused search</Display3>
      </GridItem>
      <Card>
        <GridItem className="ln-u-hard-left ln-u-margin-top" size={{ default: '1/1' }}>
          <TextAreaField
            id="refused-reason-text-area"
            name="refused-reason-text-area"
            data-testid="refused-reason-text-area"
            label="Explain why the search was refused"
            value={refusedSearchReason}
            error={error}
            maxLength={10000}
            required
            onChange={(e) => onRefusedReasonChange(e)}
          />
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/2' }}>
          <div className="ln-u-margin-right">
            <Button id="back-btn" fullWidth onClick={onBack}>Back</Button>
          </div>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/2' }}>
          <div className="ln-u-margin-left">
            <FilledButton
              id="finish-btn"
              fullWidth
              disabled={disabled}
              onClick={onFinish}
            >
              Finish
            </FilledButton>
          </div>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default RefusedSearchReason
