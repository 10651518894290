import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { ButtonText as Label1, Body1 } from '@jsluna/typography'
import { Table } from '@jsluna/table'
import {
  VISITOR,
  SAINSBURYS,
  ARGOS,
  COLLEAGUE_SEARCH,
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY
} from '../../utils/constants'
import getDay from '../../utils/helper/dateHelper'

function SearchesCompletedByDay({ randomiserOutcomesData, searchesData, brand }) {
  let randomiserOutcomes = (brand === SAINSBURYS.toLowerCase() || brand === ARGOS.toLowerCase())
    ? randomiserOutcomesData?.allRandomiserOutcomes?.filter((x) => x?.colleagueBrand?.toLowerCase() === brand)
    : randomiserOutcomesData?.allRandomiserOutcomes

  randomiserOutcomes = randomiserOutcomes
    ?.filter((x) => x?.outcome === false && x?.randomiserType === COLLEAGUE_SEARCH)
    ?.map((x) => ({
      colleagueNumber: x?.searcher?.colleagueNumber,
      name: x?.searcher?.name,
      day: getDay(x?.addedAt)
    }))

  let searches = (brand === SAINSBURYS.toLowerCase() || brand === ARGOS.toLowerCase())
    ? searchesData?.allCompletedSearches?.filter((x) => x?.searchee?.brand !== VISITOR && x?.searchee?.brand?.toLowerCase() === brand)
    : searchesData?.allCompletedSearches?.filter((x) => x?.searchee?.brand !== VISITOR)

  searches = searches?.map((x) => ({
    colleagueNumber: x?.searcher?.colleagueNumber,
    name: x?.searcher?.name,
    day: getDay(x?.searchEnd)
  }))

  const searchesAndFalseRandomisorOutcomes = searches.concat(randomiserOutcomes)

  const distinct = (value, index, self) => self.indexOf(value) === index

  const colleagueNumbers = searchesAndFalseRandomisorOutcomes.map((x) => (
    x?.colleagueNumber
  ))

  const distinctColleagueNumbers = colleagueNumbers.filter(distinct)

  const data = distinctColleagueNumbers.map((x) => ({
    name: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x)[0].name,
    sunday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === SUNDAY)?.length,
    monday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === MONDAY)?.length,
    tuesday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === TUESDAY)?.length,
    wednesday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === WEDNESDAY)?.length,
    thursday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === THURSDAY)?.length,
    friday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === FRIDAY)?.length,
    saturday: searchesAndFalseRandomisorOutcomes?.filter((y) => y?.colleagueNumber === x && y?.day === SATURDAY)?.length
  }))

  const columns = [
    {
      name: 'Name',
      accessor: (rowData) => ({ value: rowData?.name })
    },
    {
      name: 'Sun',
      accessor: (rowData) => ({ value: rowData?.sunday })
    },
    {
      name: 'Mon',
      accessor: (rowData) => ({ value: rowData?.monday })
    },
    {
      name: 'Tue',
      accessor: (rowData) => ({ value: rowData?.tuesday })
    },
    {
      name: 'Wed',
      accessor: (rowData) => ({ value: rowData?.wednesday })
    },
    {
      name: 'Thu',
      accessor: (rowData) => ({ value: rowData?.thursday })
    },
    {
      name: 'Fri',
      accessor: (rowData) => ({ value: rowData?.friday })
    },
    {
      name: 'Sat',
      accessor: (rowData) => ({ value: rowData?.saturday })
    }
  ]

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <Label1 id="searches-completed-by-day">Searches completed by day</Label1>
      </GridItem>
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <Body1 id="sub-text">Showing the colleagues that have conducted searches this week.</Body1>
      </GridItem>
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <Table
          id="table-data"
          responsive
          className="ln-u-margin-top*2"
          columns={columns}
          data={data}
        />
      </GridItem>
    </GridWrapper>
  )
}

SearchesCompletedByDay.propTypes = {
  randomiserOutcomesData: PropTypes.node.isRequired,
  searchesData: PropTypes.node.isRequired,
  brand: PropTypes.string.isRequired
}

export default SearchesCompletedByDay
