/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  recentlyAdded: null,
  items: []
}

const itemsSlice = createSlice({
  name: 'items',
  initialState,
  reducers: {
    setItem: (state, action) => {
      state.items = [...state.items, action.payload]
    },
    clearItems: (state) => {
      state.items = []
    },
    setRecentlyAddedItem: (state, action) => {
      state.recentlyAdded = action.payload
    },
    setItems: (state, action) => {
      state.items = action.payload
    },
    setRecentlyRemovedItem: (state, action) => {
      state.recentlyRemoved = action.payload
    }
  }
})

export const {
  setItem, clearItems, setRecentlyAddedItem, setItems, setRecentlyRemovedItem
} = itemsSlice.actions

export default itemsSlice.reducer
