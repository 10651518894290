/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isForbidden: null,
  hasMultipleLocations: null,
  userRoles: [],
  displaySiteSelector: false
}

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setIsForbidden: (state, action) => {
      state.isForbidden = action.payload
    },
    setHasMultipleLocations: (state, action) => {
      state.hasMultipleLocations = action.payload
    },
    setUserRoles: (state, action) => {
      state.userRoles = action.payload
    },
    setDisplaySiteSelector: (state, action) => {
      state.displaySiteSelector = action.payload
    }
  }
})

export const {
  setIsForbidden, setHasMultipleLocations, setUserRoles, setDisplaySiteSelector
} = permissionsSlice.actions

export default permissionsSlice.reducer
