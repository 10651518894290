/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { ObjectID } from 'bson'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display2, Body1 } from '@jsluna/typography'
import { TextInputField, TextAreaField } from '@jsluna/form'
import { Card } from '@jsluna/card'
import { Link } from '@jsluna/link'
import { Button, FilledButton } from '@jsluna/button'
import { decrement, increment, reset } from '../Progress/progressSlice'
import { setItem, setRecentlyAddedItem, setRecentlyRemovedItem } from './itemsSlice'
import PoundSign from '../../assets/pound-sign.svg'

function AddProduct() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [price, setPrice] = useState('')
  const [itemLocation, setItemLocation] = useState('')
  const [itemReason, setItemReason] = useState('')
  const [nameError, setNameError] = useState('')
  const [priceError, setPriceError] = useState('')
  const [itemLocationError, setItemLocationError] = useState('')
  const [itemReasonError, setItemReasonError] = useState('')

  const items = useSelector((state) => state.items.items)
  const id = useSelector((state) => state.search.id)
  const progress = useSelector((state) => state.progress.fill)
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  const FIELD_NAMES = [
    'Items'
  ]

  const SEARCH = gql`mutation updateItems (
    $siteLocationCode: String!,
    $searchId: String!,
    $fieldNames: [String!]!,
    $items: [ItemInput!]!
    ) {
    updateItems(
      siteLocationCode: $siteLocationCode,
      id: $searchId,
      fieldNames: $fieldNames,
      itemsInput: $items,
      search: {
      id: $searchId,
      site: {
        primaryAssigned: {},
        selected: {}
      },
      searcher: {},
      searchee: {},
      observer: {},
      items: $items
    }) {
      id
    }
  }`

  const [updateSearchItems] = useMutation(SEARCH)

  const onBack = () => {
    dispatch(decrement())
    dispatch(setRecentlyAddedItem(false))
    dispatch(setRecentlyRemovedItem(false))
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/items')
  }

  window.onpopstate = () => {
    if (items.length > 0) {
      dispatch(reset())
      window.localStorage.removeItem('progressFill')
      navigate('/error')
    } else {
      dispatch(increment())
    }
  }

  function validatePrice() {
    return price.match(/^\d+(?:\.\d{0,2})?$/)
  }

  const onAdd = () => {
    if (!name) {
      setNameError('Enter an item name')
    }

    if (validatePrice() === null && price !== '') {
      setPriceError('Enter a valid price, e.g. 59.99, 1.20 or 0.80')
    }

    if (!itemLocation) {
      setItemLocationError('Enter a location')
    }

    if (!itemReason) {
      setItemReasonError('Enter additional information')
    }

    if (name && itemLocation && itemReason && (validatePrice() !== null || price === '')) {
      const itemObjectId = new ObjectID()
      const itemId = itemObjectId.toHexString()

      const itemFound = {
        id: itemId,
        name,
        price: !price ? null : parseFloat(price),
        location: itemLocation,
        reason: itemReason
      }

      const itemsFound = [...items]
      itemsFound.push(itemFound)

      dispatch(setItem(itemFound))
      dispatch(decrement())
      dispatch(setRecentlyAddedItem(true))
      window.localStorage.setItem('pageRefreshed', 'false')
      updateSearchItems({
        variables: {
          siteLocationCode: locationCode,
          searchId: id,
          fieldNames: FIELD_NAMES,
          items: itemsFound
        }
      }).catch((error) => {
        if (error.networkError.toString().includes('403')) {
          navigate('/forbidden')
        } else {
          console.log(`Error updating search document with items | id ${id} | ${error}`)
          dispatch(reset())
          navigate('/error')
        }
      })
      navigate('/items')
    }
  }

  const onNameChange = (e) => {
    setName(e.target.value)
    setNameError('')
  }

  const onPriceChange = (e) => {
    setPrice(e.target.value)
    setPriceError('')
  }

  const onLocationChange = (e) => {
    setItemLocation(e.target.value)
    setItemLocationError('')
  }

  const onReasonChange = (e) => {
    setItemReason(e.target.value)
    setItemReasonError('')
  }

  const onHyperlinkClick = () => {
    dispatch(increment())
    navigate('/items/prohibited')
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <Card className="ln-u-margin-top">
        <GridItem className="ln-u-hard-left ln-u-margin-bottom" size={{ default: '1/1' }}>
          <Display2 id="title">Add an item</Display2>
        </GridItem>
        <GridItem className="ln-u-hard-left ln-u-margin-bottom*3" size={{ default: '1/1' }}>
          <Body1 id="text">
            {'You only need to record '}
            <Link
              id="prohibited-items-link"
              data-testid="prohibited-hyperlink"
              onClick={onHyperlinkClick}
            >
              prohibited items
            </Link>
            .
          </Body1>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <TextInputField
            id="item-name-text-input"
            data-testid="item-name-text-input"
            name="item-name-text-input-field"
            label="Item name"
            value={name}
            error={nameError}
            maxLength={300}
            onChange={(e) => onNameChange(e)}
          />
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <TextInputField
            id="price-input"
            data-testid="price-input"
            name="price-input-field"
            label="Price (optional)"
            icon={(
              <img
                id="pound-icon"
                data-testid="pound-icon"
                src={PoundSign}
                alt="pound sign icon"
              />
            )}
            value={price}
            error={priceError}
            maxLength={7}
            onChange={(e) => onPriceChange(e)}
          />
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <TextInputField
            id="item-location-text-input-field"
            data-testid="item-location-text-input"
            name="item-location-text-input"
            value={itemLocation}
            error={itemLocationError}
            maxLength={300}
            label="Location of item"
            onChange={(e) => onLocationChange(e)}
          />
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <TextAreaField
            id="reason-text-area"
            data-testid="reason-text-area"
            name="reason-text-area"
            label="Additional information or reason for having item"
            value={itemReason}
            error={itemReasonError}
            maxLength={10000}
            required
            onChange={(e) => onReasonChange(e)}
          />
        </GridItem>
        <GridItem className="ln-u-hard-left ln-u-margin-top" size={{ default: '1/1' }}>
          <FilledButton id="nothing-found-btn" fullWidth onClick={onAdd}>Add</FilledButton>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Button id="back-btn" className="ln-u-margin-top*4" fullWidth onClick={onBack}>Back</Button>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default AddProduct
