export const GOL = 'GOL'
export const FAST_TRACK = 'FastTrack'

export const VAN = 'Van'
export const COLLEAGUE = 'Colleague'

export const SAINSBURYS = 'Sainsburys'
export const ARGOS = 'Argos'
export const HABITAT = 'Habitat'

export const STORE = 'Store'
export const DEPOT = 'Depot'

export const DRAFT = 'Draft'
export const REFUSED = 'Refused'
export const ITEMS_FOUND = 'Items Found'
export const DONE_AND_NOTHING_FOUND = 'Done and Nothing Found'

export const VISITOR = 'Visitor'

export const ARGOS_RETAIL = 'argos retail'
export const RETAIL = 'retail'
export const LOGISTICS = 'logistics'
export const ARGOS_RFC = 'argos regional fulfilment centres'
export const ARGOS_LFC = 'argos local fulfilment centres'
export const ARGOS_DISTRIBUTION = 'argos distribution'

export const ATM_CASH_OFFICE = 'ATM room / Cash Office'
export const LOCKER = 'Locker room search'
export const PERSONAL = 'Specific personal search'
export const RANDOM = 'Standard random search'

export const COLLEAGUE_SEARCH = 'ColleagueSearch'

export const YES = 'Yes'
export const NO = 'No'

export const SUNDAY = 'Sunday'
export const MONDAY = 'Monday'
export const TUESDAY = 'Tuesday'
export const WEDNESDAY = 'Wednesday'
export const THURSDAY = 'Thursday'
export const FRIDAY = 'Friday'
export const SATURDAY = 'Saturday'

export default null
