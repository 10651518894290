import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { Link } from '@jsluna/link'
import { TextInputField } from '@jsluna/form'
import { AsyncAutocompleteField } from '@jsluna/autocomplete'
import { Button, FilledButton } from '@jsluna/button'

function Searchee({
  fieldLabel,
  isError,
  errorMsg,
  onChange,
  onSelect,
  onAutoCompleteChange,
  autoCompleteFieldValue,
  loadOptions,
  onHyperlinkClick,
  hyperlinkText,
  isColleagueSearchable,
  isManualEntry,
  textInputValue,
  textInputLabel,
  questions,
  onSecondaryClick,
  onPrimaryClick,
  defaultSelectedOption
}) {
  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <Display3 className="ln-u-margin-left*2">Search details</Display3>
      </GridItem>
      <Card className="ln-u-margin-top*2">
        {isManualEntry
          ? (
            <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
              <TextInputField
                id="manual-name-field"
                name="manual-name-field"
                data-testid="manual-name-field"
                value={textInputValue}
                label={textInputLabel}
                error={isError ? errorMsg : ''}
                onChange={(e) => onChange(e)}
              />
            </GridItem>
          )
          : (
            <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
              <AsyncAutocompleteField
                id="async-autocomplete-field"
                name="search-person-async-autocomplete-field"
                data-testid="async-autocomplete-field"
                placeholder="Start typing..."
                value={autoCompleteFieldValue}
                label={fieldLabel}
                error={isError ? errorMsg : ''}
                onSelect={(e) => onSelect(e)}
                onChange={(e) => onAutoCompleteChange(e)}
                defaultSelectedOption={defaultSelectedOption}
                loadOptions={loadOptions}
                minChars={1}
              />
            </GridItem>
          )}
        {isColleagueSearchable
        && (
        <GridItem className={isManualEntry ? 'ln-u-hard-left ln-u-margin-bottom*2' : 'ln-u-hard-left ln-u-margin-bottom*3'} size={{ default: '1/1' }}>
          <Link
            id="hyperlink"
            data-testid="hyperlink"
            className="ln-c-label u-fonts-normal"
            onClick={(e) => onHyperlinkClick(e)}
            href="/driver"
          >
            {hyperlinkText}
          </Link>
        </GridItem>
        )}
        {questions}
        <GridItem className="ln-u-hard-left" size={{ default: '1/2' }}>
          <div className="ln-u-margin-right">
            <Button
              id="secondary-btn"
              fullWidth
              onClick={onSecondaryClick}
            >
              Back
            </Button>
          </div>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/2' }}>
          <div className="ln-u-margin-left">
            <FilledButton
              id="primary-btn"
              fullWidth
              onClick={onPrimaryClick}
            >
              Continue
            </FilledButton>
          </div>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

Searchee.defaultProps = {
  fieldLabel: 'Driver name',
  textInputValue: '',
  textInputLabel: 'Add driver name manually',
  autoCompleteFieldValue: undefined,
  loadOptions: undefined,
  defaultSelectedOption: undefined
}

Searchee.propTypes = {
  fieldLabel: PropTypes.string,
  isError: PropTypes.bool.isRequired,
  errorMsg: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  autoCompleteFieldValue: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onAutoCompleteChange: PropTypes.func.isRequired,
  loadOptions: PropTypes.func,
  onHyperlinkClick: PropTypes.func.isRequired,
  hyperlinkText: PropTypes.string.isRequired,
  isColleagueSearchable: PropTypes.bool.isRequired,
  isManualEntry: PropTypes.bool.isRequired,
  textInputValue: PropTypes.string,
  textInputLabel: PropTypes.string,
  questions: PropTypes.element.isRequired,
  onSecondaryClick: PropTypes.func.isRequired,
  onPrimaryClick: PropTypes.func.isRequired,
  defaultSelectedOption: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })
}

export default Searchee
