/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  primaryAssigned: {
    locationCode: null,
    code: null,
    brand: null,
    type: null,
    hasArgosSIS: null
  },
  selected: {
    code: null,
    name: null,
    brand: null,
    type: null,
    hasArgosSIS: null
  }
}

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSiteLocationCode: (state, action) => {
      state.primaryAssigned.locationCode = action.payload
    },
    setSiteCode: (state, action) => {
      state.primaryAssigned.code = action.payload
    },
    setSiteBrand: (state, action) => {
      state.primaryAssigned.brand = action.payload
    },
    setSiteType: (state, action) => {
      state.primaryAssigned.type = action.payload
    },
    setSiteHasArgosSIS: (state, action) => {
      state.primaryAssigned.hasArgosSIS = action.payload
    },
    setSelectedSiteCode: (state, action) => {
      state.selected.code = action.payload
    },
    setSelectedSiteName: (state, action) => {
      state.selected.name = action.payload
    },
    setSelectedSiteBrand: (state, action) => {
      state.selected.brand = action.payload
    },
    setSelectedSiteType: (state, action) => {
      state.selected.type = action.payload
    },
    setSelectedSiteHasArgosSIS: (state, action) => {
      state.selected.hasArgosSIS = action.payload
    },
  }
})

export const {
  setSiteLocationCode,
  setSiteCode,
  setSiteBrand,
  setSiteType,
  setSiteHasArgosSIS,
  setSelectedSiteCode,
  setSelectedSiteName,
  setSelectedSiteBrand,
  setSelectedSiteType,
  setSelectedSiteHasArgosSIS
} = siteSlice.actions

export default siteSlice.reducer
