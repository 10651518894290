import React from 'react'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3, Body1 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { FilledButton } from '@jsluna/button'
// import { Feedback } from '@jsainsburyplc/retail-shared-components'
import SuccessImg from '../../assets/success.svg'
import { clearItems } from '../Items/itemsSlice'
import { setIsColleagueSearchable, setIsManualEntry } from '../Searchee/searcheeSlice'
import { setSearchType } from '../Search/searchSlice'
import useStateCustom from '../../customHooks/useStateCustom'
import { VISITOR } from '../../utils/constants'

function SearchOutcome({
  image,
  altImgText,
  buttonText
}) {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { resetSearcheeDetailsState, resetRandomiserAndNameState } = useStateCustom()

  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const primaryStore = useSelector((state) => state.site.primaryAssigned.code)
  const primaryStoreBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const searchStatus = useSelector((state) => state.search.status)
  const drivesFor = useSelector((state) => state.searchee.drivesFor)
  const selectedStore = useSelector((state) => state.site.selected.code)
  const selectedStoreBrand = useSelector((state) => state.site.selected.brand)
  const searchType = useSelector((state) => state.search.type)

  if (isForbidden) {
    navigate('/forbidden')
  }

  const onPrimaryBtnClick = () => {
    resetSearcheeDetailsState()
    resetRandomiserAndNameState()
    dispatch(setIsColleagueSearchable(true))
    dispatch(setSearchType(null))
    dispatch(setIsManualEntry(false))
    dispatch(clearItems())
    navigate('/')
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <Card>
        <GridItem className="ln-u-hard-left ln-u-margin-bottom*4" size={{ default: '1/1' }}>
          <img id="success" data-testid="success" style={{ display: 'block', margin: 'auto' }} src={image} alt={altImgText} />
        </GridItem>
        <GridItem style={{ textAlign: 'center' }} className="ln-u-hard-left ln-u-margin-bottom" size={{ default: '1/1' }}>
          <Display3 id="title" data-testid="title">{location.state.title ?? 'Completed'}</Display3>
        </GridItem>
        {
          location.state.outcomeType !== VISITOR && (
            <GridItem style={{ textAlign: 'center' }} className="ln-u-hard-left ln-u-margin-bottom*4" size={{ default: '1/1' }}>
              <Body1 id="text">
                <strong>{`${location.state.getRemainingCount} more ${location.state.outcomeType} `}</strong>
                <strong>{`${location.state.getRemainingCount}` === '1' ? ` ${searchType.toLowerCase()} ` : ` ${searchType.toLowerCase()}s `}</strong>
                to search this week
              </Body1>
            </GridItem>
          )
        }
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          {/* Feeback component has been temporarily commented out until it has been migrated over to azure ad */}
          {/* <Feedback
            user={user}
            appId="searches"
            signInRedirect={signInRedirect}
            metadata={{
              source: `${searchStatus} Search`,
              VanType: `${drivesFor} Van`,
              primaryStoreCode: `${primaryStore}`,
              primaryStoreBrand: `${primaryStoreBrand}`,
              selectedStoreCode: `${selectedStore}`,
              selectedStoreBrand: `${selectedStoreBrand}`,
            }}
            alwaysDisplayFeedback="false"
          /> */}
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <FilledButton id="primary-btn" fullWidth onClick={onPrimaryBtnClick}>{buttonText}</FilledButton>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

SearchOutcome.defaultProps = {
  image: `${window.location.origin}${SuccessImg}`,
  altImgText: 'success search outcome',
  buttonText: 'Next search'
}

SearchOutcome.propTypes = {
  image: PropTypes.string,
  altImgText: PropTypes.string,
  buttonText: PropTypes.string
}

export default SearchOutcome
