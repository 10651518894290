import { useDispatch } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import {
  setUserAzureId, setUserFullName, setUserLocationCode, setUserBusinessUnit, setUserJobName
} from './userSlice'

function UserDetails() {
  const dispatch = useDispatch()

  const GET_USER = gql`query
    user {
      user {
        azureId,
        fullName,
        locationCode,
        businessUnit,
        jobName
      }     
    }`

  const { data } = useQuery(GET_USER)

  dispatch(setUserAzureId(data?.user?.azureId))
  dispatch(setUserFullName(data?.user?.fullName))
  dispatch(setUserLocationCode(data?.user?.locationCode))
  dispatch(setUserBusinessUnit(data?.user?.businessUnit))
  dispatch(setUserJobName(data?.user?.jobName))
}

export default UserDetails
