import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import store from './store/store'
import App from './App'
import './main.scss'
import { msalConfig } from './utils/azure-ad/authConfig'
import ApiClientProvider from './utils/azure-ad/ApiClientProvider'

const msalInstance = new PublicClientApplication(msalConfig)

const render = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'))
  root.render(
    <MsalProvider instance={msalInstance}>
      <ApiClientProvider>
        <React.StrictMode>
          <Provider store={store}>
            <Router>
              <App />
            </Router>
          </Provider>
        </React.StrictMode>
      </ApiClientProvider>
    </MsalProvider>
  )
}

render()
