function useLocalStorage() {
  const removeLocationAndBusinessUnit = () => {
    window.localStorage.removeItem('searcheeBusinessUnit')
    window.localStorage.removeItem('searcheeLocationCode')
  }

  return {
    removeLocationAndBusinessUnit
  }
}

export default useLocalStorage
