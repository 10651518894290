/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isSiteEnabled: null
}

const trialSitesSlice = createSlice({
  name: 'trialSites',
  initialState,
  reducers: {
    setIsSiteEnabled: (state, action) => {
      state.isSiteEnabled = action.payload
    }
  }
})

export const { setIsSiteEnabled } = trialSitesSlice.actions

export default trialSitesSlice.reducer
