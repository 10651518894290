/* eslint-disable max-len */
import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Display2, Body1 } from '@jsluna/typography'
import { Button } from '@jsluna/button'
import { Link } from '@jsluna/link'
import { decrement } from '../Progress/progressSlice'

function SearchProcedures({ title, procedureList, searchSubtype }) {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const progress = useSelector((state) => state.progress.fill)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  const onBack = () => {
    dispatch(decrement())
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate(-1)
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <Card className="ln-u-margin-top">
        <GridItem className="ln-u-hard-left ln-u-margin-bottom" size={{ default: '1/1' }}>
          <Display2 id="search-procedures-title" data-testid="search-procedures-title">
            {title}
          </Display2>
        </GridItem>
        {procedureList.map((listItem, index) => (
          <GridItem
            className="ln-u-hard-left ln-u-margin-bottom*2"
            size={{ default: '1/1' }}
          >
            <Body1
              key={listItem}
              id={`${searchSubtype}-text-paragraph-${index}`}
              data-testid={`${searchSubtype}-text-paragraph-${index}`}
            >
              {listItem}
            </Body1>
          </GridItem>
        ))}
        <GridItem className="ln-u-hard-left ln-u-margin-bottom*2" size={{ default: '1/1' }}>
          <Body1 id="search-policy" data-testid="search-policy">
            For Argos colleagues use the search wand following the
            {' '}
            <Link
              id="search-procedure-link"
              href="https://jsainsbury.sharepoint.com/:w:/r/sites/DocCentre/Retail-Procedures/_layouts/15/Doc.aspx?sourcedoc=%7B77D3748E-9F66-4D6E-B902-1440D2EF5976%7D&file=Security-%20Colleague%20and%20Visitor%20Search%20Procedure.docx&action=default&mobileredirect=true&DefaultItemOpen=1&isSPOFile=1&params=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiIyNy8yMjAzMDcwMTYxMCJ9&cid=432ec21c-b169-41ea-ab50-4470dcaa9c86"
            >
              <b>Colleague and visitor search procedure</b>
            </Link>
            .
          </Body1>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <div className="ln-u-margin-right">
            <Button id="back-btn" fullWidth onClick={onBack}>Back</Button>
          </div>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

SearchProcedures.propTypes = {
  title: PropTypes.string.isRequired,
  procedureList: PropTypes.arrayOf(PropTypes.string).isRequired,
  searchSubtype: PropTypes.string.isRequired
}

export default SearchProcedures
