import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GridItem } from '@jsluna/grid'
import { RadioButtonField } from '@jsluna/form'
import Searchee from './Searchee'
import { decrement } from '../Progress/progressSlice'
import { setDrivesFor, setSearcheeBrand } from './searcheeSlice'
import { setDrivesOrWorksForError, setSearcheeNameError } from '../Error/errorSlice'
import LoadingSpinner from '../Progress/LoadingSpinner'
import { GOL, FAST_TRACK } from '../../utils/constants'
import useColleagueData from '../../customHooks/useColleagueData'
import useSearchee from '../../customHooks/useSearchee'
import useSearcheeContinue from '../../customHooks/useSearcheeContinue'
import useSearcheeBack from '../../customHooks/useSearcheeBack'

function DriverDetails() {
  const {
    onNameChange,
    onAutoCompleteNameChange,
    onSelectName,
    getDefaultSelectedOption,
    onHyperlinkClick,
    searchValue
  } = useSearchee()
  const { loading, error, getColleagueOptions } = useColleagueData()
  const { onContinueClick } = useSearcheeContinue()
  const { onBackClick } = useSearcheeBack()

  const displaySearcheeNameError = useSelector((state) => state.error.searchee.name)
  const displayDrivesForError = useSelector((state) => state.error.searchee.drivesOrWorksFor)
  const progress = useSelector((state) => state.progress.fill)
  const isSearchable = useSelector((state) => state.searchee.isColleagueSearchable)
  const isManual = useSelector((state) => state.searchee.isManualEntry)
  const name = useSelector((state) => state.searchee.name)
  const drivesFor = useSelector((state) => state.searchee.drivesFor)
  const searchId = useSelector((state) => state.search.id)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  const dispatch = useDispatch()
  const navigate = useNavigate()

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  const dropDownButtonElement = document?.getElementsByClassName('ln-c-button ln-c-input-group__action')[0]

  dropDownButtonElement?.addEventListener('click', () => {
    dispatch(setSearcheeNameError(false))
  })

  window.onpopstate = () => {
    if (searchId === null) {
      navigate('/')
    } else {
      dispatch(decrement())
    }
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    console.log('Error getting colleague names and ids | ', error)
  }

  const onSelectDrivesFor = (e) => {
    dispatch(setDrivesFor(e.target.value))
    dispatch(setDrivesOrWorksForError(false))
    if (isManual) {
      if (selectedSiteBrand !== null) {
        dispatch(setSearcheeBrand(selectedSiteBrand))
      } else {
        dispatch(setSearcheeBrand(primarySiteBrand))
      }
    }
  }

  const radioBtnQuestion = () => (
    <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
      <RadioButtonField
        id="drive-for-radio-btn"
        name="drive-for-radio-btn"
        data-testid="drive-for-radio-btn"
        className="ln-u-margin-bottom*4"
        value={drivesFor !== null ? drivesFor : ''}
        label="Who do they drive for?"
        listType="inline"
        options={[
          { label: GOL, value: GOL },
          { label: FAST_TRACK, value: FAST_TRACK }
        ]}
        error={displayDrivesForError ? 'Please select who they drive for' : ''}
        onChange={(e) => onSelectDrivesFor(e)}
      />
    </GridItem>
  )

  return (
    <Searchee
      isError={displaySearcheeNameError}
      errorMsg="Please enter the driver's name"
      onChange={onNameChange}
      onSelect={onSelectName}
      onAutoCompleteChange={onAutoCompleteNameChange}
      loadOptions={getColleagueOptions}
      onHyperlinkClick={onHyperlinkClick}
      hyperlinkText={isManual ? 'Search for driver name' : 'Can\'t find the driver\'s name?'}
      isColleagueSearchable={isSearchable}
      isManualEntry={isManual}
      questions={radioBtnQuestion()}
      textInputValue={name}
      autoCompleteFieldValue={searchValue}
      onSecondaryClick={onBackClick}
      onPrimaryClick={onContinueClick}
      defaultSelectedOption={getDefaultSelectedOption()}
    />
  )
}

export default DriverDetails
