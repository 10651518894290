import { gql, useQuery } from '@apollo/client'

function useSite(siteCode) {
  const GET_SITE = gql`query site($siteCode: String!) {
    site(siteCode: $siteCode) {
        code,
        name,
        hasArgosSIS
    }
  }`

  const { data, loading, error } = useQuery(GET_SITE, {
    variables: { siteCode },
    skip: !siteCode
  })

  return {
    data,
    loading,
    error
  }
}

export default useSite
