import React from 'react'
import { useMsal } from '@azure/msal-react'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { ApolloProvider } from '@apollo/client/react'
import { apiConfig } from './apiClientConfig'
import apiUrl from '../../api/apiConfig'

// eslint-disable-next-line react/prop-types
function ApiClientProvider({ children }) {
  const { instance, accounts } = useMsal()

  const getAccessToken = async () => {
    const account = accounts[0] ?? null

    if (accounts.length === 0) {
      return null
    }

    try {
      const authResult = await instance.acquireTokenSilent({
        account,
        scopes: [apiConfig.apiClientScope]
      })

      return authResult.accessToken
    } catch (error) {
      console.log(error)
      return null
    }
  }

  const httpLink = createHttpLink({
    uri: apiUrl
  })

  const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from our retrieved access token
    const token = await getAccessToken()

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : ''
      }
    }
  })

  const defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache'
    },
    query: {
      fetchPolicy: 'no-cache'
    }
  }

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
    defaultOptions
  })

  return (
    <ApolloProvider client={client}>
      {children}
    </ApolloProvider>
  )
}

export default ApiClientProvider
