import React from 'react'
import { useMsal } from '@azure/msal-react'

function Logout() {
  const { instance } = useMsal()

  const handleLogout = (logoutType) => {
    if (logoutType === 'popup') {
      instance.logoutPopup({
        postLogoutRedirectUri: '/',
        mainWindowRedirectUri: '/'
      })
    } else if (logoutType === 'redirect') {
      instance.logoutRedirect({
        postLogoutRedirectUri: '/'
      })
    }
  }

  return (
    <button
      className="c-logout ln-u-padding-ends ln-u-padding-sides*2"
      id="logout-btn"
      data-testid="logout-btn"
      type="button"
      onClick={() => handleLogout('redirect')}
    >
      Logout
    </button>
  )
}

export default Logout
