import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Card } from '@jsluna/card'
import { Display1, Body1, Display6 } from '@jsluna/typography'
import { OutlinedButton } from '@jsluna/button'

function SearchCard({
  title,
  buttonText,
  onClick,
  displayNames,
  counts,
  gridDefaultSize,
  displaySecondaryButton,
  secondaryButtonText,
  onSecondaryClick
}) {
  const titleForIds = title.toLowerCase()
  const buttonTextForIds = buttonText.replace(' ', '-').toLowerCase()

  return (
    <GridWrapper className="ln-u-flush-left">
      <Card className="ln-u-margin-top*2">
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Display1 id={`${titleForIds}-title`} data-testid={`${titleForIds}-title`}>{title}</Display1>
        </GridItem>
        {
          Object.values(counts).map((value, index) => (
            <GridItem className="ln-u-hard-left u-text-center" size={{ default: gridDefaultSize }}>
              <Display6
                id={`${buttonTextForIds}-${index}-count`}
                data-testid={`${buttonTextForIds}-${index}-count`}
              >
                {value}
              </Display6>
            </GridItem>
          ))
        }
        {
          Object.keys(counts).map((key) => (
            <GridItem className="ln-u-hard-left u-text-center" size={{ default: gridDefaultSize }}>
              <Body1
                id={`${titleForIds}-${key}-count-label`}
                data-testid={`${titleForIds}-${key}-count-label`}
              >
                {/* gets the first element in an array of property values for displayNames where the object
                key for displayNames passed in as props match against the property key in the counts object
                that is being looped through */}
                {Object.values(
                  Object.fromEntries(
                    Object.entries(displayNames).filter(
                      ([propKey, _]) => propKey === key
                    )
                  )
                )[0]}
              </Body1>
            </GridItem>
          ))
        }
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <OutlinedButton
            id={`${buttonTextForIds}-secondary-btn`}
            className="ln-u-margin-top*2"
            fullWidth
            onClick={onClick}
          >
            {buttonText}
          </OutlinedButton>
        </GridItem>
        {displaySecondaryButton && (
          <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
            <OutlinedButton
              id={`${buttonTextForIds}-text-btn`}
              className="ln-u-margin-top*2"
              fullWidth
              onClick={onSecondaryClick}
            >
              {secondaryButtonText}
            </OutlinedButton>
          </GridItem>
        )}
      </Card>
    </GridWrapper>
  )
}

SearchCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  displayNames: PropTypes.objectOf(PropTypes.string).isRequired,
  counts: PropTypes.objectOf(PropTypes.number).isRequired,
  gridDefaultSize: PropTypes.string.isRequired,
  displaySecondaryButton: PropTypes.bool,
  secondaryButtonText: PropTypes.string,
  onSecondaryClick: PropTypes.func
}

SearchCard.defaultProps = {
  displaySecondaryButton: false,
  secondaryButtonText: '',
  onSecondaryClick: () => {}
}

export default SearchCard
