/* eslint-disable max-len */
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GridWrapper } from '@jsluna/grid'
import SearchProcedures from './SearchProcedures'
import {
  ATM_CASH_OFFICE, LOCKER, PERSONAL, RANDOM
} from '../../utils/constants'

function ColleagueSearchProcedures() {
  const navigate = useNavigate()

  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const searchSubtype = useSelector((state) => state.search.subtype)
  const searchSubtypeForIds = searchSubtype.toLowerCase().replace(/\/\s/g, '').replace(/\s/g, '-')

  if (isForbidden) {
    navigate('/forbidden')
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      {searchSubtype === PERSONAL && (
        <SearchProcedures
          title="Personal searches"
          procedureList={[
            'Personal searches must be conducted by two people; led by a member of management or security officer, plus a colleague of the same gender to observe.',
            'Ask the colleague to show the contents of their bags, pockets, or anything else they are carrying.',
            'The colleague may be asked to empty any bags carried, empty wallets/purses, remove their shoes and socks, turn out pockets, cuffs and collars and remove outer garments such as jackets. This can also include hair accessories, hats, ties, belts, watches, and name badges.',
            'Neither the colleague nor their possessions should be touched at any time.'
          ]}
          searchSubtype={searchSubtypeForIds}
        />
      )}
      {searchSubtype === RANDOM && (
        <SearchProcedures
          title="Random searches"
          procedureList={[
            'If the result of the random selector is to search, then the colleague should be asked to show the contents of their bags, pockets, or anything else they are carrying. Neither the colleague nor their possessions should be touched at any time.',
            'If a colleague is found in possession of a locker or a vehicle key during their working hours, a search of their locker and / or vehicle may then be conducted in their presence.'
          ]}
          searchSubtype={searchSubtypeForIds}
        />
      )}
      {searchSubtype === LOCKER && (
        <SearchProcedures
          title="Locker searches"
          procedureList={[
            'Locker searches, as part of a personal or random search, must be completed in the presence of the colleague following the procedures outlined in the Colleague and visitor search procedure.',
            'At times lockers may need to be searched for other reasons, such as a health and safety concern. The locker area must be sealed off from the general colleague population whilst the searches are conducted. The search must be completed by two people: one a member of management or security officer, plus a responsible member of staff.'
          ]}
          searchSubtype={searchSubtypeForIds}
        />
      )}
      {searchSubtype === ATM_CASH_OFFICE && (
        <SearchProcedures
          title="ATM room and Cash Office searches"
          procedureList={[
            'When a colleague who has been working in the Cash Office requests a manager to attend to lock the safe, the random function must be used. If the result is a search the Manager must contact another gender appropriate colleague to complete the personal search in a suitable location. There is no requirement to print this search as it will be recorded on the app.'
          ]}
          searchSubtype={searchSubtypeForIds}
        />
      )}
    </GridWrapper>
  )
}

export default ColleagueSearchProcedures
