import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { gql, useMutation } from '@apollo/client'
import { increment, reset, setAllowBrowserBack } from '../features/Progress/progressSlice'
import { setIsManualEntry, setIsColleagueSearchable } from '../features/Searchee/searcheeSlice'
import { setSearchType, setSearchId } from '../features/Search/searchSlice'
import { setDisplaySiteSelector } from '../features/Permissions/permissionsSlice'
import { VAN, COLLEAGUE, DRAFT } from '../utils/constants'
import useStateCustom from './useStateCustom'

function useSearch() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { resetSearcheeDetailsState, resetItemsAndRefusedState } = useStateCustom()
  const randomiserOutcome = useSelector((state) => state.randomiser.outcome)
  const randomiserOutcomeId = useSelector((state) => state.randomiser.outcomeId)
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteName = useSelector((state) => state.site.selected.name)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const searcherName = useSelector((state) => state.searcher.name)
  const searcherJobRole = useSelector((state) => state.searcher.jobRole)
  const searcherColleagueId = useSelector((state) => state.searcher.colleagueNumber)
  const searcheeAddedManually = useSelector((state) => state.searchee.isManualEntry)

  const SEARCH = gql`mutation createSearch (
    $searchStatus: String!,
    $searchType: String!,
    $isRandomSearch: Boolean,
    $randomiserOutcomeId: String,
    $siteLocationCode: String!,
    $selectedSiteCode: String,
    $selectedSiteName: String,
    $selectedSiteBrand: String,
    $selectedSiteType: String,
    $searcherName: String!,
    $searcherJobRole: String!,
    $colleagueId: String
    ) {
    createSearch(
      siteLocationCode: $siteLocationCode,
      search: {
      id: "",
      searchStatus: $searchStatus,
      searchType: $searchType,
      isRandomSearch: $isRandomSearch,
      randomiserOutcomeId: $randomiserOutcomeId,
      site: {
        primaryAssigned: {
          locationCode: $siteLocationCode,
        },
        selected: {
          code: $selectedSiteCode,
          name: $selectedSiteName,
          brand: $selectedSiteBrand,
          type: $selectedSiteType
        }
      }
      searcher: {
        name: $searcherName,
        jobRole: $searcherJobRole,
        colleagueNumber: $colleagueId
      },
      searchee: {},
      observer: {},
      items: []
    }) {
      id
    }
  }`

  const [insertSearch] = useMutation(SEARCH)

  const onSearchClick = (searchType) => {
    resetSearcheeDetailsState()
    resetItemsAndRefusedState()
    dispatch(setAllowBrowserBack(true))
    dispatch(setDisplaySiteSelector(false))
    dispatch(setIsColleagueSearchable(true))
    dispatch(setIsManualEntry(false))
    dispatch(increment())
    dispatch(setSearchType(searchType))
    window.localStorage.setItem('pageRefreshed', 'false')
    insertSearch({
      variables: {
        searchStatus: DRAFT,
        searchType,
        isRandomSearch: randomiserOutcome === true,
        randomiserOutcomeId,
        siteLocationCode: locationCode,
        selectedSiteCode,
        selectedSiteName,
        selectedSiteBrand,
        selectedSiteType,
        searcherName,
        searcherJobRole,
        colleagueId: searcherColleagueId
      }
    }).then((response) => {
      const searchId = response.data.createSearch.id
      dispatch(setSearchId(searchId))
      window.localStorage.setItem('searchId', searchId)
      window.localStorage.setItem('searcheeAddedManually', searcheeAddedManually)
    }).catch((err) => {
      window.localStorage.removeItem('searchId')
      if (err.networkError.toString().includes('403')) {
        navigate('/forbidden')
      } else {
        console.log('Error inserting search document | ', err)
        dispatch(reset())
        navigate('/error')
      }
    })

    if (searchType === VAN) {
      navigate('/driver')
    }

    if (searchType === COLLEAGUE) {
      navigate('/colleague/details')
    }
  }

  return {
    onSearchClick
  }
}

export default useSearch
