import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display1, Display2, Caption } from '@jsluna/typography'
import { useSelector } from 'react-redux'
import { ProgressSpinner } from '@jsluna/progress'
import ErrorCircle from '../../assets/error-circle-blue.svg'
import ThumbUp from '../../assets/thumb-up.svg'
import AlertIcon from '../../assets/alert-icon.svg'
import useStateCustom from '../../customHooks/useStateCustom'

function Result({
  title,
  altImgText,
  loading,
  error
}) {
  const { resetRandomiserAndNameState } = useStateCustom()
  const randomiserOutcome = useSelector((state) => state.randomiser.outcome)

  window.onpopstate = () => {
    resetRandomiserAndNameState()
  }

  if (error) {
    return (
      <div className="c-result-border ln-u-padding*4 u-colors-monochrome-light">
        <GridItem className="c-result-container ln-u-hard-left">
          <img id="alert-icon" data-testid="error-icon" className="ln-u-margin-bottom" src={AlertIcon} alt={altImgText} />
        </GridItem>
        <GridItem className="c-result-container ln-u-hard-left">
          <Display1 id="randomiser-result-text" data-testid="result-text">
            Sorry an error occurred, please try again
          </Display1>
        </GridItem>
      </div>
    )
  }

  if (loading) {
    return (
      <GridWrapper className="ln-u-flush-left">
        <GridItem className="ln-u-hard-left ln-u-margin-bottom">
          <Display2 id="result-title" data-testid="result-title">
            {title}
          </Display2>
        </GridItem>
        <GridItem id="result-container" className="c-result-border ln-u-padding*3 u-colors-monochrome-light c-result-container ln-u-hard-left">
          <ProgressSpinner className="ln-u-margin*4" />
        </GridItem>
      </GridWrapper>
    )
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom">
        <Display2 id="result-title" data-testid="result-title">
          {title}
        </Display2>
      </GridItem>
      {randomiserOutcome === true && (
        <div className="c-result-border ln-u-padding*4 u-colors-monochrome-light">
          <GridItem className="c-result-container ln-u-hard-left">
            <img id="error-circle" data-testid="search-colleague-icon" className="ln-u-margin-bottom" src={ErrorCircle} alt={altImgText} />
          </GridItem>
          <GridItem className="c-result-container ln-u-hard-left">
            <Display1 id="randomiser-result-text" data-testid="result-text">
              Search colleague
            </Display1>
          </GridItem>
        </div>
      )}
      {randomiserOutcome === false && (
        <div className="c-result-border ln-u-padding*4 u-colors-monochrome-light">
          <GridItem className="c-result-container ln-u-hard-left">
            <img id="thumb-up" data-testid="no-search-icon" className="ln-u-margin-bottom" src={ThumbUp} alt={altImgText} />
          </GridItem>
          <GridItem className="c-result-container ln-u-hard-left">
            <Display1 id="randomiser-result-text" data-testid="result-text">
              No search needed
            </Display1>
          </GridItem>
        </div>
      )}
      {randomiserOutcome === null && (
        <GridItem id="result-container" className="c-result-border ln-u-padding*3 u-colors-monochrome-light c-result-container ln-u-hard-left">
          <Caption id="instruction-result-text" data-testid="result-text" className="ln-u-padding*4 u-text-center ln-u-margin">
            Touch button above to start randomiser ...
          </Caption>
        </GridItem>
      )}
    </GridWrapper>
  )
}

Result.defaultProps = {
  altImgText: 'outcome icon',
}

Result.propTypes = {
  title: PropTypes.string.isRequired,
  altImgText: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired
}

export default Result
