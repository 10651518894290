import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card } from '@jsluna/card'
import { FilledButton as Button } from '@jsluna/button'
import { Display1 } from '@jsluna/typography'
import { reset as resetProgressBar } from '../Progress/progressSlice'
import useStateCustom from '../../customHooks/useStateCustom'
import useLocalStorage from '../../customHooks/useLocalStorage'

function Error() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    resetSearcheeDetailsState,
    resetSearchState,
    resetItemsAndRefusedState
  } = useStateCustom()
  const { removeLocationAndBusinessUnit } = useLocalStorage()

  const onStartAgain = () => {
    dispatch(resetProgressBar())
    resetSearcheeDetailsState()
    resetSearchState()
    resetItemsAndRefusedState()
    removeLocationAndBusinessUnit()
    navigate('/')
  }

  return (
    <Card>
      <div className="u-text-center">
        <Display1>Something went wrong, please try again</Display1>
      </div>
      <Button
        id="error-page-button"
        fullWidth
        className="ln-u-margin-top*2"
        onClick={onStartAgain}
      >
        Start again
      </Button>
    </Card>
  )
}

export default Error
