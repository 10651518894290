import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { setIsColleagueSearchEnabled } from './featureToggleSlice'
import LoadingSpinner from '../Progress/LoadingSpinner'

function FeatureToggle() {
  const dispatch = useDispatch()

  const primaryLocationCode = useSelector((state) => state.user.locationCode)

  const IS_COLLEAGUE_SEARCH_ENABLED = gql`
  query isColleagueSearchEnabled($siteLocationCode: String!) {
    isColleagueSearchEnabled(siteLocationCode: $siteLocationCode)
  }`

  const { data, loading, error } = useQuery(IS_COLLEAGUE_SEARCH_ENABLED, {
    variables: { siteLocationCode: primaryLocationCode },
    skip: !primaryLocationCode
  })

  dispatch(setIsColleagueSearchEnabled(data?.isColleagueSearchEnabled))

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    console.log('Error determining whether colleague search is enabled | ', error)
  }
}

export default FeatureToggle
