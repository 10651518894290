/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display2, Body1 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { Button } from '@jsluna/button'
import { decrement } from '../Progress/progressSlice'
import useProhibitedItemExclusions from '../../customHooks/useProhibitedItemExclusions'
import LoadingSpinner from '../Progress/LoadingSpinner'

function ProhibitedItems() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { data, loading } = useProhibitedItemExclusions()

  const progress = useSelector((state) => state.progress.fill)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    if (progress !== null) {
      window.localStorage.setItem('progressFill', progress)
    }
  }, [progress])

  const onBack = () => {
    dispatch(decrement())
    window.localStorage.setItem('pageRefreshed', 'false')
    navigate('/items/add')
  }

  if (loading) {
    return <LoadingSpinner />
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <Card className="ln-u-margin-top">
        <GridItem className="ln-u-hard-left ln-u-margin-bottom" size={{ default: '1/1' }}>
          <Display2 id="prohibited-items-title" data-testid="prohibited-items-title">Prohibited items</Display2>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Body1 id="prohibited-items-text" data-testid="prohibited-items-text">
            {'Please record all items '}
            <b>except:</b>
          </Body1>
          <ul>
            {data?.prohibitedItemExclusions.map(
              (listItem, index) => <li key={listItem} id={index} data-testid={listItem} className="ln-u-margin-left">{listItem}</li>
            )}
          </ul>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <div className="ln-u-margin-right">
            <Button id="back-btn" fullWidth onClick={onBack}>Back</Button>
          </div>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default ProhibitedItems
