/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  selectedPeriodWeekStartDate: null
}

const searchHistorySlice = createSlice({
  name: 'searchHistory',
  initialState,
  reducers: {
    setSelectedPeriodWeekStartDate: (state, action) => {
      state.selectedPeriodWeekStartDate = action.payload
    }
  }
})

export const { setSelectedPeriodWeekStartDate } = searchHistorySlice.actions

export default searchHistorySlice.reducer
