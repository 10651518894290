import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { reset, decrement } from '../features/Progress/progressSlice'
import useStateCustom from './useStateCustom'
import useLocalStorage from './useLocalStorage'

function useSearcheeBack() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    resetSearcheeDetailsState,
    resetSearchState,
    resetErrors,
    resetRandomiserAndNameState
  } = useStateCustom()
  const { removeLocationAndBusinessUnit } = useLocalStorage()
  const progress = useSelector((state) => state.progress.fill)

  const onBackClick = () => {
    window.localStorage.removeItem('searchId')
    window.localStorage.removeItem('progressFill')
    window.localStorage.removeItem('searcheeAddedManually')
    removeLocationAndBusinessUnit()
    window.localStorage.setItem('pageRefreshed', 'false')
    resetSearcheeDetailsState()
    resetRandomiserAndNameState()
    resetSearchState()
    resetErrors()
    if (progress <= 1 / 2) {
      dispatch(reset())
    } else {
      dispatch(decrement())
    }
    navigate('/')
  }

  return {
    onBackClick
  }
}

export default useSearcheeBack
