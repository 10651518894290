import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'
import { setIsSiteEnabled } from './trialSitesSlice'
import LoadingSpinner from '../Progress/LoadingSpinner'

function TrialSites() {
  const dispatch = useDispatch()

  const primaryAssignedLocationCode = useSelector((state) => state.site.primaryAssigned.locationCode)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const primaryAssignedSiteCode = useSelector((state) => state.site.primaryAssigned.code)

  const siteCode = selectedSiteCode ?? primaryAssignedSiteCode

  const IS_SITE_ENABLED_FOR_TRIAL = gql`
  query isSiteEnabledForColleagueSearchTrial($siteLocationCode: String!, $siteCode: String!) {
    isSiteEnabledForColleagueSearchTrial(siteLocationCode: $siteLocationCode, siteCode: $siteCode)
  }`

  const { data, loading, error } = useQuery(IS_SITE_ENABLED_FOR_TRIAL, {
    variables: { siteLocationCode: primaryAssignedLocationCode, siteCode },
    skip: !primaryAssignedLocationCode || !siteCode
  })

  dispatch(setIsSiteEnabled(data?.isSiteEnabledForColleagueSearchTrial))

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    console.log('Error determining if site is allowed | ', error)
  }
}

export default TrialSites
