import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { gql, useLazyQuery } from '@apollo/client'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Body1 } from '@jsluna/typography'
import { Alert } from '@jsluna/alert'
import SearchCard from './SearchCard'
import LoadingSpinner from '../Progress/LoadingSpinner'
import { VAN } from '../../utils/constants'

function VanSearch({ onClick }) {
  const primarySiteCode = useSelector((state) => state.site.primaryAssigned.code)
  const primarySiteBrand = useSelector((state) => state.site.primaryAssigned.brand)
  const primarySiteType = useSelector((state) => state.site.primaryAssigned.type)
  const selectedSiteCode = useSelector((state) => state.site.selected.code)
  const selectedSiteBrand = useSelector((state) => state.site.selected.brand)
  const selectedSiteType = useSelector((state) => state.site.selected.type)
  const siteCode = selectedSiteCode == null ? primarySiteCode : selectedSiteCode
  const siteBrand = selectedSiteCode == null ? primarySiteBrand : selectedSiteBrand
  const siteType = selectedSiteCode == null ? primarySiteType : selectedSiteType

  const [vanCountsData, setVanCountsData] = useState(null)

  const GET_VAN_COUNTS = gql`query vanCounts($siteCode: String!, $siteBrand: String!, $siteType: String!) {
    vanCounts(siteCode: $siteCode, siteBrand: $siteBrand, siteType: $siteType) {
      gol,
      fastTrack,
      from,
      to
    }
  }`

  const [getVanCounts, { loading }] = useLazyQuery(GET_VAN_COUNTS, {
    variables: { }
  })

  useEffect(() => {
    if (siteCode && siteType && siteBrand) {
      getVanCounts({ variables: { siteCode, siteBrand, siteType } }).then((response) => {
        setVanCountsData(response.data)
      })
    }
  }, [getVanCounts, siteCode, siteType, siteBrand])

  if (loading) {
    return <LoadingSpinner />
  }

  const vanCounts = ['gol', 'fastTrack'].reduce((result, key) => ({
    ...result,
    [key]: vanCountsData?.vanCounts[key]
  }), {}) // removes properties from the original object returned from the api so we are left with only gol and fastTrack

  const filteredVanCounts = Object.fromEntries(
    Object.entries(vanCounts).filter(
      ([_, value]) => value !== null
    )
  ) // excludes items from the object where the value is null so we don't display in the UI on render of SearchCard

  const golDisplayName = filteredVanCounts.gol === 1 ? 'GOL van' : 'GOL vans'
  const fastTrackDisplayName = filteredVanCounts.fastTrack === 1 ? 'FastTrack van' : 'FastTrack vans'

  const vanDisplayNames = {
    gol: golDisplayName,
    fastTrack: fastTrackDisplayName
  }

  const gridDefaultSizeVan = `1/${Object.keys(filteredVanCounts).length}`

  return (
    <GridWrapper className="ln-u-flush-left">
      {vanCountsData?.vanCounts?.gol === null && vanCountsData?.vanCounts?.fastTrack === null
        ? (
          <GridItem className="ln-u-margin-top*2 ln-u-hard-left" size={{ default: '1/1' }}>
            <Alert variant="info">
              <div className="ln-u-margin-left">
                <Body1
                  id="no-vans-allocated-text"
                  data-testid="no-vans-allocated-text"
                >
                  There are currently no vans allocated to your store. If you have vans in store, please
                  <strong> contact the IT Service Desk </strong>
                  to let us know you&apos;ve got vans at your store.
                </Body1>
              </div>
            </Alert>
          </GridItem>
        )
        : (
          <SearchCard
            title="Vans"
            buttonText="Van search"
            gridDefaultSize={gridDefaultSizeVan}
            displayNames={vanDisplayNames}
            counts={filteredVanCounts}
            onClick={() => onClick(VAN)}
          />
        )}
    </GridWrapper>
  )
}

VanSearch.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default VanSearch
