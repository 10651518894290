import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { gql, useQuery } from '@apollo/client'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { Button } from '@jsluna/button'
import { Pagination } from '@jsluna/pagination'
import LoadingSpinner from '../Progress/LoadingSpinner'
import {
  setSelectedSiteCode, setSelectedSiteName, setSelectedSiteBrand, setSelectedSiteType
} from '../Site/siteSlice'
import {
  ARGOS, HABITAT, SAINSBURYS, STORE
} from '../../utils/constants'
import { setIsForbidden, setHasMultipleLocations, setUserRoles } from '../Permissions/permissionsSlice'

function SiteSelector() {
  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const hasMultipleLocations = useSelector((state) => state.permissions.hasMultipleLocations)
  const userRoles = useSelector((state) => state.permissions.userRoles) ?? []

  const [currentPage, setCurrentPage] = useState(1)

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const ADMIN = 'admin'

  const isForbiddenUser = isForbidden ?? window.sessionStorage.getItem('isForbidden') === 'true'
  const userHasMultipleLocations = hasMultipleLocations ?? window.sessionStorage.getItem('hasMultipleLocations') === 'true'
  const rolePermissions = [window.sessionStorage.getItem('userRoles')]
  const userRolePermissions = userRoles.length === 0 ? rolePermissions : userRoles

  useEffect(() => {
    if (isForbidden === null) {
      dispatch(setIsForbidden(isForbiddenUser))
      dispatch(setHasMultipleLocations(userHasMultipleLocations))
      dispatch(setUserRoles(userRolePermissions))
    }
  }, [isForbidden, dispatch, isForbiddenUser, userHasMultipleLocations, userRolePermissions])

  let GET_SITES = gql`query
  sitesByAssignedLocations {
    sitesByAssignedLocations {
      code,
      name
    }   
  }`

  if (!isForbiddenUser && userRolePermissions.includes(ADMIN)) {
    GET_SITES = gql`query($page: Int!) {
      sites(page: $page) {
          pagination {
            limit,
            offset,
            total
          }
          sites {
            code,
            name
          }
      }
    }`
  }

  const { data, loading } = useQuery(GET_SITES, { variables: { page: currentPage } })

  if (loading) {
    return <LoadingSpinner />
  }

  const onSiteClick = (e) => {
    if (e?.target?.innerText?.includes(ARGOS)) {
      dispatch(setSelectedSiteBrand(ARGOS))
    } else if (e?.target?.innerText?.includes(HABITAT)) {
      dispatch(setSelectedSiteBrand(HABITAT))
    } else {
      dispatch(setSelectedSiteBrand(SAINSBURYS))
    }

    dispatch(setSelectedSiteType(STORE))

    const siteNameAndCode = e?.target?.innerText?.split('(')
    const siteName = siteNameAndCode ? siteNameAndCode[0].trim() : ''
    const siteCode = siteNameAndCode ? siteNameAndCode[1].replace(')', '') : ''
    dispatch(setSelectedSiteName(siteName))
    dispatch(setSelectedSiteCode(siteCode))

    if (location?.state?.siteCode === siteCode && location?.state?.siteName === siteName) {
      navigate(location.state.incomingRoute)
    } else {
      navigate('/')
    }
  }

  const displaySites = (site) => (
    <GridItem
      key={`${site.code}-${site.name.replace(' ', '')}`}
      className="ln-u-hard-left"
      size={{ default: '1/1' }}
    >
      <Button
        id={`${site.code}-${site.name.replace(' ', '')}`}
        key={`${site.code}-${site.name.replace(' ', '')}`}
        hard
        variant="text"
        element="button"
        color="dark"
        onClick={(e) => onSiteClick(e)}
      >
        {`${site.name} (${site.code})`}
      </Button>
    </GridItem>
  )

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2 u-margin-top" size={{ default: '1/1' }}>
        <Display3 id="title" className="ln-u-margin-left*2">Select your store</Display3>
      </GridItem>
      <Card>
        {userRolePermissions.includes(ADMIN) ? (
          data?.sites?.sites?.map((x) => (
            displaySites(x)
          ))
        ) : (
          data?.sitesByAssignedLocations?.map((x) => (
            displaySites(x)
          ))
        )}
        {!isForbiddenUser && userRolePermissions.includes(ADMIN)
        && (
        <Pagination
          id="site-pagination"
          className="ln-u-margin-top*3"
          showFirstAndLast
          showPages
          current={currentPage}
          total={Math.ceil(data.sites.pagination.total / data.sites.pagination.limit)}
          onChange={(page, e) => {
            e.preventDefault()
            setCurrentPage(page)
          }}
        />
        )}
      </Card>
    </GridWrapper>
  )
}

export default SiteSelector
