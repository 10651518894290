import { useDispatch } from 'react-redux'
import {
  setSearcheeName,
  setColleagueNumber,
  setDrivesFor,
  setSearcheeJobRole,
  setBusinessUnit,
  setLocationCode,
  setSearcheeBrand,
  setSearcheeType,
  setSearcheeVisitorCompany,
  setIsColleagueSearchable,
  setIsManualEntry
} from '../features/Searchee/searcheeSlice'
import {
  setSearchSubtype,
  setCctvCaptured,
  setSearchType,
  setSearchId,
  setSearchStatus,
  setRefusedSearchReason
} from '../features/Search/searchSlice'
import { setObserverName } from '../features/Observer/observerSlice'
import { clearItems, setRecentlyAddedItem, setRecentlyRemovedItem } from '../features/Items/itemsSlice'
import {
  setColleagueNameError,
  setSearcheeNameError,
  setDrivesOrWorksForError,
  setSearchSubtypeError,
  setCompanyNameError,
  setCctvCapturedError,
  setObserverNameError
} from '../features/Error/errorSlice'
import { setRandomiserOutcomeId, setRandomiserOutcome, setColleagueName } from '../features/Randomiser/randomiserSlice'
import { DRAFT } from '../utils/constants'

function useStateCustom() {
  const dispatch = useDispatch()

  const resetSearcheeDetailsState = () => {
    dispatch(setSearcheeName(''))
    dispatch(setColleagueNumber(''))
    dispatch(setDrivesFor(''))
    dispatch(setSearcheeJobRole(''))
    dispatch(setBusinessUnit(''))
    dispatch(setLocationCode(''))
    dispatch(setSearcheeBrand(''))
    dispatch(setSearcheeType(''))
    dispatch(setSearcheeVisitorCompany(''))
    dispatch(setSearchSubtype(''))
    dispatch(setCctvCaptured(null))
    dispatch(setObserverName(''))
  }

  const resetRandomiserState = () => {
    dispatch(setRandomiserOutcomeId(null))
    dispatch(setRandomiserOutcome(null))
  }

  const resetRandomiserAndNameState = () => {
    dispatch(setRandomiserOutcomeId(null))
    dispatch(setRandomiserOutcome(null))
    dispatch(setColleagueName(''))
    dispatch(setColleagueNameError(false))
  }

  const resetSearchState = () => {
    dispatch(setIsColleagueSearchable(true))
    dispatch(setSearchType(null))
    dispatch(setSearchId(null))
    dispatch(setIsManualEntry(false))
  }

  const resetItemsAndRefusedState = () => {
    dispatch(setSearchStatus(DRAFT))
    dispatch(setRefusedSearchReason(null))
    dispatch(clearItems())
    dispatch(setRecentlyAddedItem(null))
    dispatch(setRecentlyRemovedItem(null))
  }

  const resetErrors = () => {
    dispatch(setSearcheeNameError(false))
    dispatch(setDrivesOrWorksForError(false))
    dispatch(setSearchSubtypeError(false))
    dispatch(setCompanyNameError(false))
    dispatch(setCctvCapturedError(false))
    dispatch(setObserverNameError(false))
  }

  return {
    resetSearcheeDetailsState,
    resetRandomiserState,
    resetRandomiserAndNameState,
    resetSearchState,
    resetItemsAndRefusedState,
    resetErrors
  }
}

export default useStateCustom
