/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: null,
  jobRole: null,
  colleagueNumber: null
}

const searcherSlice = createSlice({
  name: 'searcher',
  initialState,
  reducers: {
    setSearcherName: (state, action) => {
      state.name = action.payload
    },
    setSearcherJobRole: (state, action) => {
      state.jobRole = action.payload
    },
    setSearcherColleagueNumber: (state, action) => {
      state.colleagueNumber = action.payload
    }
  }
})

export const { setSearcherName, setSearcherJobRole, setSearcherColleagueNumber } = searcherSlice.actions

export default searcherSlice.reducer
