/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isColleagueSearchEnabled: null
}

const featureToggleSlice = createSlice({
  name: 'featureToggle',
  initialState,
  reducers: {
    setIsColleagueSearchEnabled: (state, action) => {
      state.isColleagueSearchEnabled = action.payload
    }
  }
})

export const { setIsColleagueSearchEnabled } = featureToggleSlice.actions

export default featureToggleSlice.reducer
