/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: '',
  colleagueNumber: '',
  isColleagueSearchable: true,
  isManualEntry: false,
  drivesFor: '',
  jobRole: '',
  businessUnit: '',
  locationCode: '',
  brand: '',
  type: '',
  visitorCompany: ''
}

const searcheeSlice = createSlice({
  name: 'searchee',
  initialState,
  reducers: {
    setSearcheeName: (state, action) => {
      state.name = action.payload
    },
    setIsColleagueSearchable: (state, action) => {
      state.isColleagueSearchable = action.payload
    },
    setIsManualEntry: (state, action) => {
      state.isManualEntry = action.payload
    },
    setDrivesFor: (state, action) => {
      state.drivesFor = action.payload
    },
    setColleagueNumber: (state, action) => {
      state.colleagueNumber = action.payload
    },
    setSearcheeJobRole: (state, action) => {
      state.jobRole = action.payload
    },
    setBusinessUnit: (state, action) => {
      state.businessUnit = action.payload
    },
    setLocationCode: (state, action) => {
      state.locationCode = action.payload
    },
    setSearcheeBrand: (state, action) => {
      state.brand = action.payload
    },
    setSearcheeType: (state, action) => {
      state.type = action.payload
    },
    setSearcheeVisitorCompany: (state, action) => {
      state.visitorCompany = action.payload
    }
  }
})

export const {
  setIsColleagueSearchable,
  setIsManualEntry,
  setSearcheeName,
  setDrivesFor,
  setColleagueNumber,
  setSearcheeJobRole,
  setBusinessUnit,
  setLocationCode,
  setSearcheeBrand,
  setSearcheeType,
  setSearcheeVisitorCompany
} = searcheeSlice.actions

export default searcheeSlice.reducer
