/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  colleagueName: '',
  outcomeId: null,
  outcome: null
}

const randomiserSlice = createSlice({
  name: 'randomiser',
  initialState,
  reducers: {
    setColleagueName: (state, action) => {
      state.colleagueName = action.payload
    },
    setRandomiserOutcomeId: (state, action) => {
      state.outcomeId = action.payload
    },
    setRandomiserOutcome: (state, action) => {
      state.outcome = action.payload
    }
  }
})

export const {
  setColleagueName,
  setRandomiserOutcomeId,
  setRandomiserOutcome
} = randomiserSlice.actions

export default randomiserSlice.reducer
