import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3, Body1 } from '@jsluna/typography'
import { Card } from '@jsluna/card'
import { TextInputField } from '@jsluna/form'
import { Button, OutlinedButton } from '@jsluna/button'
import { decrement } from '../Progress/progressSlice'
import { COLLEAGUE } from '../../utils/constants'
import { setSearchType } from '../Search/searchSlice'
import { setColleagueName } from './randomiserSlice'
import { setColleagueNameError } from '../Error/errorSlice'
import useSearch from '../../customHooks/useSearch'
import useStateCustom from '../../customHooks/useStateCustom'
import RandomiserOutcome from './RandomiserOutcome'
import isFullNameValid from '../../utils/helper/validationHelper'

function Randomiser() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { onSearchClick } = useSearch()
  const { resetRandomiserAndNameState, resetRandomiserState } = useStateCustom()

  const searchType = useSelector((state) => state.search.type)
  const isForbidden = useSelector((state) => state.permissions.isForbidden)
  const colleagueName = useSelector((state) => state.randomiser.colleagueName)
  const isColleagueNameError = useSelector((state) => state.error.randomiser.colleagueName)
  const randomiserOutcome = useSelector((state) => state.randomiser.outcome)

  if (isForbidden) {
    navigate('/forbidden')
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    dispatch(setSearchType(COLLEAGUE))
  }, [dispatch, searchType])

  const onBack = () => {
    resetRandomiserAndNameState()
    dispatch(setSearchType(null))
    dispatch(decrement())
    navigate('/')
  }

  const onColleagueNameChange = (e) => {
    resetRandomiserState()
    dispatch(setColleagueName(e.target.value))
    if (isFullNameValid(e.target.value)) {
      dispatch(setColleagueNameError(false))
    }
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left ln-u-margin-bottom*2 ln-u-margin-left*2 ln-u-margin-top" size={{ default: '1/1' }}>
        <Display3 data-testid="randomiser-title">Use randomiser</Display3>
      </GridItem>
      <Card>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <TextInputField
            id="randomiser-name-field"
            name="randomiser-name-field"
            data-testid="randomiser-name-field"
            value={colleagueName}
            label="Colleague full name"
            placeholder="e.g. John Smith"
            error={isColleagueNameError ? 'Please enter the colleague\'s first and last name separated by a single space' : ''}
            onChange={(e) => onColleagueNameChange(e)}
          />
        </GridItem>
        <GridItem className="ln-u-hard-left">
          <Body1 data-testid="randomiser-text">
            Get the colleague to hit the button, to see if they need searching or not.
          </Body1>
        </GridItem>
        <RandomiserOutcome />
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <OutlinedButton
            data-testid="randomiser-search-btn"
            id="record-search-btn"
            fullWidth
            onClick={() => onSearchClick(COLLEAGUE)}
            disabled={randomiserOutcome === false}
          >
            Record a search
          </OutlinedButton>
        </GridItem>
        <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
          <Button
            data-testid="randomiser-back-btn"
            id="secondary-btn"
            fullWidth
            onClick={onBack}
          >
            Back
          </Button>
        </GridItem>
      </Card>
    </GridWrapper>
  )
}

export default Randomiser
