import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { Display3 } from '@jsluna/typography'
import { Tabs, TabLink, TabPanel } from '@jsluna/tabs'
import { Button } from '@jsluna/button'
import { setSelectedPeriodWeekStartDate } from './searchHistorySlice'
import DropDownSelector from '../../components/DropDownSelector/DropDownSelector'
import LoadingSpinner from '../Progress/LoadingSpinner'
import SearchesCompleted from './SearchesCompleted'
import useFinancialYearData from '../../customHooks/useFinancialYearData'
import { ARGOS_RETAIL, ARGOS, SAINSBURYS } from '../../utils/constants'

function SearchHistory() {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const selectedPeriodWeekStartDate = useSelector((state) => state.searchHistory.selectedPeriodWeekStartDate)
  const primaryAssignedSiteHasArgosSis = useSelector((state) => state.site.primaryAssigned.hasArgosSIS)
  const selectedSiteHasArgosSis = useSelector((state) => state.site.selected.hasArgosSIS)
  const siteHasArgosSis = selectedSiteHasArgosSis ?? primaryAssignedSiteHasArgosSis
  const userBusinessUnit = useSelector((state) => state.user.businessUnit)

  const tabs = [
    { name: ARGOS, key: ARGOS.toLowerCase() },
    { name: 'Sainsbury\'s', key: SAINSBURYS.toLowerCase() },
    { name: 'Both', key: 'both' }
  ]

  const defaultTab = userBusinessUnit.toLowerCase() === ARGOS_RETAIL ? ARGOS.toLowerCase() : SAINSBURYS.toLowerCase()

  const [activeTab, setActiveTab] = useState(defaultTab)

  const { data, loading, error } = useFinancialYearData()

  const periodWeekList = data?.financialYearData?.map((x) => {
    const weekStartDate = new Date(x.week.startDate)
    return {
      label: `P${x.period.periodNumber} WK${x.week.weekEnumerator}`,
      value: weekStartDate.getTimezoneOffset() === -60
        ? new Date(weekStartDate.setHours(weekStartDate.getHours() + 1)).toISOString()
        : `${x.week.startDate}`
    }
  })

  if (periodWeekList && selectedPeriodWeekStartDate === null) {
    dispatch(setSelectedPeriodWeekStartDate(periodWeekList?.[0]?.value))
  }

  const onDropDownSelect = (e) => {
    dispatch(setSelectedPeriodWeekStartDate(e.target.value))
  }

  const onBack = () => {
    dispatch(setSelectedPeriodWeekStartDate(null))
    navigate('/')
  }

  if (loading) {
    return <LoadingSpinner />
  }

  if (error) {
    console.log('Error getting financial year periods and weeks | ', error)
  }

  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <Display3
          id="search-history-title"
          className="ln-u-margin-left*2"
        >
          Previous searches
        </Display3>
      </GridItem>
      <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
        <DropDownSelector
          id="dropdown-selector"
          className="ln-u-margin-sides*2 ln-u-margin-top"
          label="Change week"
          options={periodWeekList}
          defaultValue={`${periodWeekList?.[0]?.value}`}
          onChange={(e) => onDropDownSelect(e)}
        />
      </GridItem>
      {siteHasArgosSis
        ? (
          <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
            <Tabs
              className="tab-links"
              type="fill"
            >
              {tabs.map((tab) => (
                <TabLink
                  key={tab.key}
                  onClick={() => setActiveTab(tab.key)}
                  active={activeTab === tab.key}
                >
                  {tab.name}
                </TabLink>
              ))}
            </Tabs>
            <TabPanel>
              <SearchesCompleted
                brand={activeTab}
              />
            </TabPanel>
          </GridItem>
        )
        : (
          <GridItem className="ln-u-hard-left u-margin-top" size={{ default: '1/1' }}>
            <SearchesCompleted
              brand="all"
            />
          </GridItem>
        )}
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <div className="ln-u-margin-right ln-u-margin-top*2">
          <Button
            id="back-btn"
            fullWidth
            onClick={onBack}
          >
            Back
          </Button>
        </div>
      </GridItem>
    </GridWrapper>
  )
}

export default SearchHistory
