import React from 'react'
import PropTypes from 'prop-types'
import { GridWrapper, GridItem } from '@jsluna/grid'
import { SelectField } from '@jsluna/form'

function DropDownSelector({
  className, label, options, defaultValue, onChange
}) {
  return (
    <GridWrapper className="ln-u-flush-left">
      <GridItem className="ln-u-hard-left" size={{ default: '1/1' }}>
        <SelectField
          name="drop-down-selector"
          className={className}
          label={label}
          options={options}
          defaultValue={defaultValue}
          placeholder={false}
          onChange={onChange}
        />
      </GridItem>
    </GridWrapper>
  )
}

DropDownSelector.propTypes = {
  className: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  }).isRequired
}

export default DropDownSelector
