import { useSelector } from 'react-redux'
import { gql, useQuery } from '@apollo/client'

function useFinancialYearData() {
  const locationCode = useSelector((state) => state.site.primaryAssigned.locationCode)

  const GET_FY_DATA = gql`query financialYearData($siteLocationCode: String!) {
    financialYearData (
      siteLocationCode: $siteLocationCode,
      previousWeeks: 8,
      futureWeeks: 0
      ) {
      period {
        periodNumber
      },
      week {
        startDate,
        weekEnumerator
      }
    }
  }`

  const { data, loading, error } = useQuery(GET_FY_DATA, {
    variables: { siteLocationCode: locationCode },
    skip: !locationCode
  })

  return {
    data,
    loading,
    error
  }
}

export default useFinancialYearData
