/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  fill: null,
  loading: false,
  allowBrowserBack: true
}

const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    increment: (state) => {
      state.fill += 1 / 3
    },
    decrement: (state) => {
      state.fill -= 1 / 3
    },
    reset: (state) => {
      state.fill = null
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    setProgressFill: (state, action) => {
      state.fill = action.payload
    },
    setAllowBrowserBack: (state, action) => {
      state.allowBrowserBack = action.payload
    }
  }
})

export const {
  increment, decrement, reset, setLoading, setProgressFill, setAllowBrowserBack
} = progressSlice.actions

export default progressSlice.reducer
