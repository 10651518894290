import React from 'react'
import { useSelector } from 'react-redux'

function ProgressBar() {
  const progress = useSelector((state) => state.progress.fill)

  const getProgress = () => {
    switch (true) {
      case progress < 1 / 3:
        return 'c-fill-width-empty'
      case progress >= 1 / 3 && progress < 2 / 3:
        return 'c-fill-width-one-third'
      case progress >= 2 / 3 && progress < 1:
        return 'c-fill-width-two-thirds'
      case progress >= 1:
        return 'c-fill-width-full'
      default:
        return ''
    }
  }

  const progressStyle = `c-progress-bar-fill ${getProgress()}`

  return (
    <div id="progress-bar-container" data-testid="progress-bar-container" className="c-progress-bar-container">
      <div id="progress-bar-fill" data-testid="progress-bar-fill" className={progressStyle} />
    </div>
  )
}

export default ProgressBar
