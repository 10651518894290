/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  name: ''
}

const observerSlice = createSlice({
  name: 'observer',
  initialState,
  reducers: {
    setObserverName: (state, action) => {
      state.name = action.payload
    }
  }
})

export const { setObserverName } = observerSlice.actions

export default observerSlice.reducer
