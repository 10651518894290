/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit'
import { DRAFT } from '../../utils/constants'

const initialState = {
  id: null,
  status: DRAFT,
  type: null,
  subtype: '',
  cctvCaptured: null,
  refusedReason: null
}

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setSearchId: (state, action) => {
      state.id = action.payload
    },
    setSearchStatus: (state, action) => {
      state.status = action.payload
    },
    setSearchType: (state, action) => {
      state.type = action.payload
    },
    setSearchSubtype: (state, action) => {
      state.subtype = action.payload
    },
    setCctvCaptured: (state, action) => {
      state.cctvCaptured = action.payload
    },
    setRefusedSearchReason: (state, action) => {
      state.refusedReason = action.payload
    }
  }
})

export const {
  setSearchId, setSearchStatus, setSearchType, setSearchSubtype, setCctvCaptured, setRefusedSearchReason
} = searchSlice.actions

export default searchSlice.reducer
